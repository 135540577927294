<template>
    <div class="nudge_configuration">
        <h2 style="text-align: center;">Nudges & Playbook configuration</h2>
        <div class="" style="position: absolute;right: 0px;top: 0px;">
            <img style="cursor: pointer;" src="../assets/img/feed_close.svg" @click="close" />
        </div>
        <div class="" style="display: flex;align-items: center;justify-content: space-between;padding-right: 25px;">
        <div class="tabs">
            <div @click="handleTab(idx)" :class="selectedtab == idx ? 'selectedTab' : ''" class="tab"
                v-for="(tab, idx) of tabs">
                {{ tab }}
            </div>
            
        </div>
            <div class="" style="display: flex;align-items: center;">
                    <span @click="handleTriggerNudge" v-tooltip.left="'Run Nudge'" style="color: green;font-size: 20px;margin-right: 15px; cursor: pointer;" class="material-symbols-outlined">
play_circle
</span>                <div  class="">
                    <img v-tooltip.left="'Delete Nudge'" @click="deleteNudge"
                        style="filter: invert(11%) sepia(94%) saturate(7149%) hue-rotate(359deg) brightness(98%) contrast(110%);cursor:pointer;height: 16px;"
                        src="../assets/delete.svg" />
    
                </div>
            
            </div>
        </div>

        <div class="body" v-if="selectedtab == 0">
            <div class="leftBar">
                <input id="nudgename" :class="{ error: saveClicked && !nudgeData.name }" class="nudgename"
                    v-model="nudgeData.name" type="text" placeholder="Enter a unique name" />
                <textarea :class="{ error: saveClicked && !nudgeData.customMsg }" id="nudgeMsg" class="nudgedesc"
                    v-model="nudgeData.customMsg" rows="5" type="text"
                    placeholder="Enter description for the playbook. This will be visible for the playbook participants when they get the alert" />
                <div class="nudgesetup">
                    <div class="nudgesetup__heading">
                        <h4>Nudge Setup</h4>
                    </div>
                    <div class="nudgesetup__desc">
                        Click on each card to configure accounts playbook to drive proactive action
                    </div>

                    <div :class="selectedAction == 0 ? 'nudgesetp__card_selected' : ''" @click="handleAction(0)"
                        class="nudgesetup__card">
                        <div class="nudgesetup__card__btn">
                            TRIGGER
                        </div>
                        <div class="nudgesetup__card__desc">
                            This will {{ nudgeData.trigger }} trigger
                            <div v-if="nudgeData.trigger == 'auto'" class="">
                                <span v-for="(query, idx) of nudgeData['query']">
                                    {{ getReadableQuery(query) }}
                                    <span v-if="(idx) <= nudgeData['query'].length - 2" class="">
                                        and
                                    </span>

                                </span>
                            </div>
                        </div>
                        <div class="nudgesetup__card__empty"></div>
                        <div class="nudgesetup__action">
                            <img style="height: 12px;transform: rotate(-90deg);" src="../assets/downarrow.png" />
                        </div>
                    </div>
                    <div :class="selectedAction == 1 ? 'nudgesetp__card_selected' : ''" @click="handleAction(1)"
                        class="nudgesetup__card">
                        <div class="nudgesetup__card__btn">
                            ACTION
                        </div>
                        <div class="nudgesetup__card__desc">
                            {{ nudgeData['tasks'].length }} Tasks will be created
                        </div>
                        <div class="nudgesetup__card__empty"></div>

                        <div class="nudgesetup__action">
                            <img style="height: 12px;  transform: rotate(-90deg);" src="../assets/downarrow.png" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="rightBar">
                <div class="actionBar" v-if="selectedAction == 0">
                    <div class="playBookBar__heading">TRIGGER</div>
                    <div :class="nudgeData['trigger'] == 'auto' ? 'nudgesetp__card_selected' : ''"
                        @click="nudgeData['trigger'] = 'auto'" class="actionBar__card">
                        <input v-model="nudgeData['trigger']" value="auto" type="radio" />
                        <div class="actionBar__card__content">
                            <span class="actionBar__card__heading">Based on an event</span>
                            <br />
                            <span class="actionBar__card__desc">When an event takes place, AppEQ will generate
                                notifications and/or create tasks to prompt your account owners to take proactive
                                actions.</span>

                        </div>
                        <img src="../assets/automation.png" style="height: 24px;margin-left: 25px;" />

                    </div>
                    <div :class="nudgeData['trigger'] == 'manual' ? 'nudgesetp__card_selected' : ''"
                        @click="nudgeData['trigger'] = 'manual'" class="actionBar__card">
                        <input v-model="nudgeData['trigger']" value="manual" type="radio" />
                        <div class="actionBar__card__content">
                            <span class="actionBar__card__heading">Manual Trigger</span>
                            <br />
                            <span class="actionBar__card__desc">This can be manually initiated by your account owners
                                and managers directly from the account tab. For example, in the event of an escalation,
                                trigger the escalation playbook.</span>

                        </div>
                        <img src="../assets/settings.png" style="height: 24px;margin-left: 25px;" />

                    </div>
                    <div v-if="nudgeData['trigger'] == 'auto'" style="margin-top: 20px;" class="">
                        <Multiselect @select="handleBusinessObjectSelectTemp()"
                            v-model="bObjectName" placeholder="Choose Business Object"
                            :options="bObjectList" />
                        <!-- <div v-for="(query,queryIdx) of nudgeData['query']" style="display: flex;align-items: center;justify-content: space-around;" class="">
                            <Multiselect  openDirection="below" @select="handleSeletcKeysTemp(queryIdx)"
                :hide-selected="true" v-model="query['keys']" :options="tempKeys" /> 
                             <Multiselect  label="label" @select="handleSeletcKeysTemp(queryIdx)" v-model="query['keys']" :options="tempKeys"/> -->
                        <!-- <Multiselect label="label" v-model="query['operetor']" style="margin-left: 10px;" :options="operetors"/> -->
                        <!-- <vue-autosuggest 
                                v-model="query['value']"
                                :input-props="{id:'autosuggest__input', placeholder:'Enter Value?',onInputChange: onInputChange(query['value'],queryIdx) }"
                                :suggestions="filteredOptions" >
                                <template slot-scope="{suggestion}">
    <span class="my-suggestion-item">{{suggestion.item}}</span>
  </template>
></vue-autosuggest> -->
                        <!-- <Multiselect taggable="true" @tag="addTag($event, queryIdx)" :multiple="true" v-model="query['value']" style="margin-left: 10px;" :options="tempValues[queryIdx]"/>
                            <img @click="deleteQuery(queryIdx)" v-if="queryIdx!=0" src="../assets/img/feed_close.svg" style="margin-top:8px;cursor: pointer;" /> -->

                        <!-- </div> -->
        <div v-if="showFilterModal" style="height: 100%; width: 100%; position: absolute; top: 0px; left: 0px; z-index: 9999999999999; background-color: rgba(0, 0, 0, 0.2); border-radius: 9px;">

                            <FilterModal @save="handleSaveFilter($event)" @close="showFilterModal = false;"
                                :business_object_name="bObjectName"
                                :nudgeFilter="true" />
                        </div>
                        <div @click="showFilterModal = true" class="addBtn"> + Add Filter Condition</div>
                        <h3 style="margin-bottom: 4px;margin-left: 8px;">Filter Condition</h3>
                        <div class="row" style="margin-left: 5px;margin-top: 0px !important;">
                            <div style="margin:3px 6px;display: flex;font-size: 16px;align-items: center;"
                                v-for="(fil, idx) of filterCond">
                                <div style="position: relative;" class="filerBox">
                                    {{ fil.str }}
                                    <img @click="deleteFilterCond(idx)" class="delete"
                                        src="../assets/img/feed_close.svg"
                                        style="height: 20px;position: absolute;right: 0px;top: 0px;" />
                                </div>
                                <div class="" v-if="idx < filterCond.length - 1">
                                    and
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="playbookBar" v-else-if="selectedAction == 1">
                    <div class="playBookBar__heading">
                        ACTION
                    </div>
                    <div class="playBookBar__tasks__heading__title">
                        Notification
                    </div>
                    <div class="playBookBar__desc">

                        <div class="playBookBar__btn">
                            <input checked disabled type="checkbox">
                            In-App
                        </div>
                        <div class="playBookBar__info">
                            A notification will be sent to the users participating in the playbook

                        </div>
                    </div>
                    <div class="playBookBar__desc">

<div class="playBookBar__btn">
    <input v-model="nudgeData.autoComplete" type="checkbox">
    Auto-Completion
</div>
<div class="playBookBar__info">
    Automatically complete the task when its trigger condition is no longer valid
</div>
</div>
                    <div class="playBookBar__tasks">
                        <div class="playBookBar__tasks__heading">
                            <div class="playBookBar__tasks__heading__title">
                                PlayBook Tasks
                            </div>
                            <div @click="addTasks()" class="playBookBar__tasks__heading__btn">
                                + Add
                            </div>
                        </div>
                        <div class="playBookBar__tasks_table">
                            <table style="width: 100%;">
                                <tr>
                                    <th :style="{width:col.width}" v-for="col of tasksColumn">
                                        {{ col.title }}
                                    </th>
                                </tr>

                                <tr class="tasksContent" style="margin-top: 10px;padding: 10px;"
                                    v-for="(data, taskIdx) of nudgeData['tasks']">
                                    <td style="padding: 10px;" v-for="col of tasksColumn">
                                        <div style="display: flex;align-items: center;" class=""
                                            v-if="col.key == 'action'">
                                            <div v-tooltip="'Edit Task'" class="">
                                                <img @click="handleOpenTask(data, taskIdx)"
                                                    style="height: 16px;opacity: 0.5;cursor: pointer;"
                                                    src="../assets/penciledit.svg" />
                                            </div>
                                            <div v-tooltip="'Delete Task'" class="">
                                                <img @click="deleteTask(taskIdx)"
                                                    style="height: 16px;cursor: pointer;opacity: 0.5;margin-left: 10px;"
                                                    src="../assets/delete.svg" />

                                            </div>

                                        </div>
                                        <div v-else-if="col.key == 'due_date'" class="">
                                            T + {{ data[col.key] }}
                                        </div>
                                        <div v-tooltip="{ content: data[col.key] ? data[col.key] : '' }" v-else class="">
                                            {{ data[col.key] ? data[col.key].substr(0, 30) : "NA" }}{{ data[col.key] &&
                data[col.key].length > 30 ? "..." : "" }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <div style="width: 100%;display: flex; align-items: center;justify-content: center;flex-direction: column;"
                                class="" v-if="nudgeData['tasks'].length == 0">
                                <img src="../assets/nodata.svg" style="height: 250px;" alt="">
                                <div class="">
                                    No Tasks Assigned
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="nudgeData['trigger'] == 'auto'" style="margin-top: 10%;font-size: 12px;color: grey;" class="">
                    Note - Automation will be picked up only once - the first time this condition is met and
                    corresponding tasks be
                    created.

                </div>

            </div>
        </div>
        <div style="margin-top: 20px;" class="" v-else>
            <div style="height: 70vh; overflow: scroll;">
                <div style="display: flex;align-items: center; justify-content: space-between; padding: 8px 10px;width: 90%;" class=""
                v-for="log of logsNudges">
                <div style="display: flex;align-items: center;" class="log-info">
                    <span style="flex: 0.45;" class="log-message">Custom Nudge <span style="font-weight: 600;" :style="{'color': (formatMessage(log.message)=='failed') ?'red':''}">{{ formatMessage(log.message) }}</span> for {{ nudgeData.name }}</span>
                <span style="flex: 0.45;" class="log-started-at">{{ formatDateTime(log.startedAt) }}</span>
                <!-- <span style="margin-left: 1rem; flex: 0.4;"> Added: 5, updated: 4, total: {{ log.data }}</span> -->
                </div>

            </div>
            </div>

        </div>
        <div class="personalfooter">
            
            <div class="personalfooter__btn">
                <button @click="close" class="personalfooter__btn--cancel">Cancel</button>
                <button @click="handleSaveNudge">Save</button>

            </div>

        </div>
        <Modal class="modal-search" :show="showTaskPopup">
            <TaskPopup @save="saveTasks($event)" :editMode="editTask" :task="selectedTask" @close="showTaskPopup=false"
                v-if="showTaskPopup" />
        </Modal>
    </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import Server from './API/Server';
import { operetors } from '../pages/Functions';
import { VueAutosuggest } from "vue-autosuggest";
import FilterModal from './FilterModal.vue';
import Modal from './Modal.vue';
import TaskPopup from './TaskPopup.vue';

export default {
    props: {
        nudge: {},
        isEditingIdx: -1,
        appeq_org_id:""
    },
    components: {
        Multiselect,
        Modal,
        TaskPopup,
        FilterModal,
        VueAutosuggest
    },
    async mounted() {
        // if(localStorage.getItem("ALL_CREDS") && JSON.)
        if(!localStorage.getItem("ALL_CREDS") && !JSON.parse(localStorage.getItem("ALL_CREDS"))){
            const data = await Server.googleSheetCreds();

            localStorage.setItem("ALL_CREDS", JSON.stringify(data.data));
        }
        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        this.bObjectList = creds.map(data => data.business_object_name)
        setTimeout(() => {
            document.querySelector(".nudge_configuration").style.right = "0px"

        }, 200)
        this.nudgeData = { ...this.nudge }
        // this.autoComplete=this.nudgeData['autoComplete']
        console.log(this.nudgeData)
        this.nudgeData.query.map(d => {
            this.tempValues.push([])
        })
        if (this.nudgeData && this.nudgeData['query'] && this.nudgeData['query'].length != 0 && this.nudgeData['query'][0]['business_object_name']!="") {
            this.bObjectName=this.nudgeData['query'][0]['business_object_name']
            this.handleBusinessObjectSelectTemp()
        }
        if(this.isEditingIdx!=-1){
            this.nudgeData['query'].map(d => {
            console.log(d)
            this.filterCond.push({
                filter: {
                    key: {
                        key: d.keys
                    },
                    business_object_name: d.business_object_name,
                    operator: d.operator,
                    value: d.value,

                },
                str: `If ${d.keys} ${d.operator.label} ${d.value}`
            })
        })
        }
    },
    data() {
        return {
            tabs: ["Configuration", "Logs"],
            selectedtab: 0,
            autoComplete:false,
            isFields: false,
            logsNudges: {},
            saveClicked: false,
            tempValues: [[]],
            tempKeys: [],
            bObjectList: [],
            filteredOptions: [],
            showFilterModal: false,
            filterCond: [],
            selectedAction: 0,
            editTask: false,
            operetors: operetors,
            showTaskPopup: false,
            selectedTask: null,
            bObjectName:"",
            tasksColumn: [
                {
                    title: "Name",
                    key: "task_name",
                    width:"10%"
                },
                {
                    title: "Description",
                    key: "task_desc",
                    width:"20%"

                },
                {
                    title: "Type",
                    "key": "type",
                    width:"5%"

                },
                {
                    title: "Due Date",
                    key: "due_date",
                    width:"10%"

                },
                {
                    title: "Assignee",
                    key: "user",
                    width:"5%"

                },
                {
                    title: "Action",
                    key: "action",
                    width:"5%"

                }
            ],
            nudgeData: {

            },
        }
    },
    methods: {
        handleTriggerNudge(){
            Server.triggerautoNudge(this.appeq_org_id,this.isEditingIdx)
            this.$toasted.success("Nudge Triggered Successfully")
        },
        formatDateTime(dateTimeString) {
            var originalDate = new Date(dateTimeString);
            var day = originalDate.getDate();
            var monthIndex = originalDate.getMonth();
            var year = originalDate.getFullYear();

            // Define an array of month names
            var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            // Format the date in the desired format
            return day + "-" + monthNames[monthIndex] + "-" + year + ", " + originalDate.toLocaleTimeString();
        },
        deleteQuery(idx) {
            this.nudgeData['query'].splice(idx, 1)
        },
        addTag(newTag, index) {
            console.log(this.tempValues);
            console.log(this.nudgeData);
            const parts = newTag.split(', ');

            const tag = newTag;
            this.tempValues[index].push(tag);
            this.nudgeData['query'][index]['value'].push(tag)
            console.log(this.tempValues);
            console.log(this.nudgeData);
        },
        onInputChange(text, index) {
            console.log(text, index)
            if (text === '' || text === undefined) {
                return;
            }
            /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
            if (this.tempValues.length > index && this, this.tempValues[index].length != 0) {
                console.log(this.tempValues[index])
                const filteredData = this.tempValues[index][0].data.filter(item => {
                    return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
                }).slice(0, this.limit);
                console.log(filteredData)
                this.filteredOptions = [{
                    data: filteredData
                }];
            }
        }
        ,
        deleteTask(idx) {
            this.nudgeData['tasks'].splice(idx, 1)
        },
        formatMessage(data){
                let temp = '';
                if(data.includes('FINISHED')){
                    temp = 'completed';
                }else if(data.includes('RUNNING')){
                    temp = 'triggered';
                }else{
                    temp = 'failed';
                }
                return temp;
        },
        handleSaveNudge() {
            if (this.nudgeData && this.nudgeData.query) {
                this.nudgeData.query=[]
                for (let i = 0; i < this.filterCond.length; i++) {
                    this.nudgeData.query.push({business_object_name:this.bObjectName,autoComplete:this.autoComplete, 'keys': this.filterCond[i].filter.key.key, 'operator': this.filterCond[i].filter.operator, 'value': this.filterCond[i].filter.value });
                }
            }
            if (!this.nudgeData.name || !this.nudgeData.customMsg) {
                this.saveClicked = true

                this.$toasted.error("Please fill in all details before saving the nudge")
                if (!this.nudgeData.name) {
                    // document.getElementById("nudgename").style.borderBottom='1px solid red'
                }
                if (!this.nudgeData.customMsg) {

                    // document.getElementById("nudgeMsg").style.borderBottom='1px solid red'

                }
                return
            }
            this.$emit("save", this.nudgeData)
        },
        async handleSeletcKeysTemp(index) {
            console.log(this.nudgeData[index])
            const res = await Server.getValue(this.nudgeData['query'][index]['keys'], this.nudgeData['query'][0]['business_object_name']);
            this.tempValues[index] = res.data
            var ans = false
            var dateFormat = 'DD-MM-YYYY';
            console.log(this.tempValues[index])
            // if (this.tempValues) {
            //     this.tempValues[index].map(data => {
            //         if (moment(moment(data).format(dateFormat), dateFormat, true).isValid()) {
            //             ans = true;
            //         }
            //     })
            // }
            // if (ans) {
            //     this.tempValues[index].unshift("TODAY()")
            // }
        },
        saveTasks(event) {
            this.showTaskPopup = false;
            console.log(this.selectedTask)
            if(this.selectedTask && this.selectedTask.idx>=0)
                this.nudgeData['tasks'][this.selectedTask.idx] = event.task
            else 
                this.nudgeData['tasks'].push(event.task)
            
        },
        getReadableQuery(query) {
            if (!query) {
                return ""
            }
            const operatorMap = {
                "$gte": "Greater Than Equal to",
                "$lte": "Less Than Equal to",
                "$gt": "Greater Than",
                "$lt": "Less Than",
                "$eq": "Equal to",
                "$regex": "Contains"
            };

            const readableOperator = operatorMap[query.operator.query];
            if (!query.value) {
                return ""
            }
            return `${query['keys']} is ${readableOperator} ${query.value.toString()}`;
        },

        addTasks() {
            this.selectedTask = null
            this.showTaskPopup = true;
        },
        handleOpenTask(task, idx) {
            this.selectedTask = { task, idx };
            this.editTask = true;
            this.showTaskPopup = true
        },
        handleAction(index) {
            this.selectedAction = index
        },
        async handleSaveFilter(e) {
            console.log('working og nudges', e);
            this.filterCond.push(e);
            this.showFilterModal = false;
        },
        deleteFilterCond(idx) {
            console.log(idx);
            if (this.isEditingIdx == -1) {
                this.nudgeData.query.splice(idx, 1);
            } else {
                this.nudgeData.query.splice(idx, 1);
            }
            this.filterCond.splice(idx, 1)
        },
        async handleBusinessObjectSelectTemp() {
            const data = await Server.getConfiguratorKeys(this.nudgeData['query'][0]['business_object_name'])
            // if(data && data.data && data.data.fields && data.data.fields.length>0){
            //     this.tempKeys = data.data.fields;
            //     this.isFields = true;
            //     return;
            // }
            if (data && data.data && data.data.keys)
                this.tempKeys = (data.data.keys.map((d) => d));
        },
        async deleteNudge() {
            this.$emit("delete")
        },
        async addNudgeCond() {
            this.nudgeData.query.push({
                keys: "",
                operator: "",
                value: ""
            })
            const data = await Server.getConfiguratorKeys(this.nudgeData['query'][0]['business_object_name'])

            if (data && data.data && data.data.keys)
                this.tempKeys = (data.data.keys.map((d) => d));
            this.tempValues.push([])
        },
        async handleTab(idx) {
            this.selectedtab = idx
            this.logsNudges = (await Server.getNudgesLog(this.nudgeData.name)).data
        },
        close() {
            this.$emit("close")
        }
    }
}
</script>

<style lang="scss" scoped>
.nudge_configuration {
    position: fixed;
    right: -92vw;
    transition: right 1s ease-in-out;
    width: 92vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999;
    border-radius: 20px 0px 0px 20px;
    padding: 20px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);

}

.tabs {
    display: flex;
    align-items: center;
}

.tab {
    margin-left: 10px;
}

.selectedTab {
    border-bottom: 2px solid blue;
    font-weight: 700;
}

.body {
    padding: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.nudgename {
    padding: 4px;
    outline: none;
    width: 100%;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid #dbd7d7;
}

.nudgedesc {
    margin-top: 25px;
    width: 100%;
    font-size: 12px;

    outline: none;
    border: none;
    border-bottom: 1px solid #dbd7d7;
}

.leftBar {
    width: 35%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
}

.nudgesetup {
    margin-top: 25px;

    &__card {
        padding: 12px;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;

        &__btn {
            border-radius: 8px;
            font-size: 12px;
            padding: 8px;
            font-weight: 500;
            background-color: #E9F1FB;
            margin-right: 20px;
        }

        &__empty {
            flex: 1;
        }
    }

    &__action {
        img {
            height: 20px;
        }
    }

}

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 5px;
    padding: 0px 10px;
    padding-right: 10px;

    :first-child {
        margin-left: 0px !important;
    }
}

.tab {
    color: black;
    margin-left: 40px;
    font-size: 16px;
    cursor: pointer;
    word-spacing: 2px;
}

.rightBar {
    padding: 20px 50px;
    margin-left: 25px;
    width: 60%;
    height: 100%;
    border-radius: 12px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );

    padding-bottom: 20px;
    // background-color: #4e5fff;
    background-color: #E9F1FB;

}

.actionBar {
    width: 100%;

    &__card {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 18px 15px;
        cursor: pointer;
        border-radius: 8px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        width: 100%;

        &__heading {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 6px;
        }

        &__content {
            margin-left: 25px;
            flex: 1;
            font-size: 12px;
            padding-right: 12px;
            color: grey;
        }
    }
}

.addBtn {
    font-size: 12px;
    font-weight: 700;
    color: blue;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}

.personalfooter {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 25px;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0.2;
        height: 25px;

        &--cancel {
            background-color: none !important;
            background: transparent !important;
            color: #4e5fff;
            border: 1px solid #4e5fff;
        }

        button {
            width: 45%;
            height: 35px;

            border-radius: 8px;
        }
    }

    img {
        height: 20px;
    }
}

.playBookBar {
    &__info {
        flex: 1;
    }

    &__heading {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    &__desc {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__btn {
        width: 20%;
        border: 1px solid #efefef;
        padding: 8px;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        align-items: center;

        input {
            margin-right: 10px;
        }
    }

    &__tasks {
        &__heading {
            font-size: 14px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                font-weight: 700;

            }

            &__btn {
                border-radius: 8px;
                border: 1px solid blue;
                padding: 8px 12px;
                cursor: pointer;
                width: 15%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:hover {
                    background-color: #4e5fff;
                    color: white;
                }
            }
        }
    }
}

.nudgesetp__card_selected {
    border: 1px solid #4e5fff !important;
}

.nudgesetup__card__desc {
    margin-right: 30px;
    font-size: 12px;
}

.error {
    border-bottom: 1px solid red !important;
}

.delete {
    filter: invert(17%) sepia(99%) saturate(7275%) hue-rotate(1deg) brightness(102%) contrast(114%);
    height: 20px;
    margin-right: -4px;
    width: 21px;
    cursor: pointer;
}
</style>
<style>
#autosuggest__input {
    outline: none;
    color: #35495e;
    position: relative;
    display: block;
    font-family: monospace;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 10px;
    margin-left: 10px;
    margin-top: 10px;
    height: 45px;
    width: 245px;
    margin-left: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    margin-left: 10px;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 12px;
    overflow: scroll;
    max-height: 200px;
}

.autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.autosuggest__results .autosuggest__results_item {
    cursor: pointer;
    padding: 15px;
}

#autosuggest ul:nth-child(1)>.autosuggest__results_title {
    border-top: none;
}

.autosuggest__results .autosuggest__results_title {
    color: #adadad;
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
    background-color: #ddd;
}

.tasksContent:hover {
    td:hover {
        background-color: #ffffff;

    }

    background-color: #f5f5f5;
}

.filerBox {
    padding: 8px 18px !important;
    width: 100% !important;
    height: 100% !important;
    font-size: 12px !important;
}

.logs-container {
    max-width: 600px;
    margin: 20px auto;
}

.log-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.log-info {
    flex-grow: 1;
}

.log-message {
    font-weight: bold;
    margin-right: 10px;
}

.log-started-at {
    color: #666;
}

.log-status {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}

.running {
    background-color: blue;
}

.success {
    background-color: #4caf50;
    /* Green */
}

.error {
    background-color: yellow;
    /* Red */
}
</style>
<template>
    <!-- <EmailForm v-if="true"/> -->
    <div   class="dashboard">
        <div class="dashboard__header">
            <div class="dashboard__header--title">
                <img src="../assets/img/dashboardwidget.png" />
                <span>Smart Workbench</span>
            </div>
            <div class="dashboard__header--icons">
                <img style="height: 14px;cursor: pointer;margin-right: 10px;" @click="handleBack"
                    v-if="backButton.length > 1" src="../icons/leftArrow.svg" />
                <img v-if='email.split("@")[1] == "appeq.ai"' @click="handleRedCondition" src="https://appeq.ai/wp-content/uploads/2024/09/EWS.gif"
                    style="height: 16px;margin-right: 10px;" />
                <div class="dropdown-container">
                    <img @click="handleHomeClick" style="margin-left: 0px; height: 22px;"
                        src="../assets/img/HomeIcon1.png" />
                    <div v-if="totalDashboards.length > 1" class="moreDashboards">
                        <h4 style="margin-bottom: 5px;">Dashboards</h4>
                        <hr style="margin-top: 0px !important;margin-bottom: 0px !important;" />
                        <div>
                            <div style="display: flex;align-items: center;justify-content: flex-start;font-size: 10px;margin-top: 10px;cursor: pointer;"
                                @click="openDashForThis(dash)" v-for="dash of totalDashboards">
                                <img v-if="dash.metabasePic" class="minimizeList"
                                    :src='require("../icons/" + dash.metabasePic)'
                                    style="height: 14px;margin-right: 5px; margin-left: 0px;" />
                                {{ dash.metabaseTitle }}

                            </div>
                        </div>
                    </div>
                </div>
                <!-- <img src="../assets/redCondition.webp" style="height: 14px;"/> -->
              <img v-tooltip.bottom-end="'Templates'"
                    :style="{ 'filter': (showTemplates) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)' : 'invert(100%) sepia(10%) saturate(3746%) hue-rotate(180deg) brightness(31%) contrast(8%)' }"
                    @click="handleShowTemplates" src="../assets/img/templates.png" />
                <div 
                    style="border: 1px solid grey;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 4px;margin-left: 10px;">
                    <img v-tooltip.bottom-end="'Tasks'" style="height: 12px;margin-left: 0px !important;"
                        :style="{ 'filter': (showTasks) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)' : 'invert(100%) sepia(10%) saturate(3746%) hue-rotate(180deg) brightness(31%) contrast(8%)' }"
                        @click="handleShowTasks" src="../icons/clipboard.png" />
                </div>
                <div 
                    style="border: 1px solid grey;border-radius: 50%;display: flex;align-items: center;justify-content: center;padding: 4px;margin-left: 10px;">
                    <img v-tooltip.bottom-end="'Feeds'" style="height: 12px;margin-left: 0px !important;"
                        :style="{ 'filter': (false) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)' : 'invert(100%) sepia(10%) saturate(3746%) hue-rotate(180deg) brightness(31%) contrast(8%)' }"
                        @click="handleShowticket" src="../icons/feed_icon.svg" />
                </div>
                
                <!-- <img v-tooltip.bottom-end="'Ask Genie'" style="height: 26px;" :style="{'filter': (showAiModal) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)':''}" @click="showAiModal = !showAiModal;reGenerate=false;openDownloadModal=false;opsnShareModal=false;" src="../assets/img/AI Icon.png" /> -->
            </div>
            <div class="dashboard__header--end">
                <img v-if="!showTasks" style="height: 14px;" v-tooltip.bottom-end="'Download'"
                    :style="{ 'filter': (openDownloadModal) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)' : 'invert(100%) sepia(10%) saturate(3746%) hue-rotate(180deg) brightness(31%) contrast(8%)' }"
                    @click="openDownloadModal = !openDownloadModal; opsnShareModal = false;"
                    src="../assets/img/download.png" />
                <img  v-if="!showTasks" style="height: 14px;" v-tooltip.bottom-end="'Share'"
                    :style="{ 'filter': (opsnShareModal) ? 'invert(9%) sepia(100%) saturate(5469%) hue-rotate(246deg) brightness(107%) contrast(151%)' : 'invert(189%) sepia(149%) saturate(808%) hue-rotate(149deg) brightness(-25%) contrast(172%)' }"
                    @click="opsnShareModal = !opsnShareModal; openDownloadModal = false;"
                    src="../assets/img/sharesmartDashboard.png" />
                <img v-if="!showTasks" style="height: 14px;" v-tooltip.bottom-end="'Refresh'" @click="handleReload" src="../icons/refresh.png" alt="">

                <img   v-tooltip.bottom-end="'Full Screen'" style="height: 14px;" v-if="showFullScreenIcon && !showTasks" @click="handleGotoFullScreen" src="../assets/img/fullscreen.png" alt="">
                <img  v-else-if="!showTasks" @click="exitFullScreen" src="../assets/img/exitFullscreen.png" alt="">
                <!-- <img  @click="handlePhoneView" src="../assets/img/phone.png" />
            <img @click="handlePcView" src="../assets/img/pc.png" /> -->
                <img @click="closeDash" style="height: 25px;" src="../assets/img/feed_close.svg" />

            </div>
        </div>
        <div v-if="!showTemplates && !showTasks && !showRed" class="dashboard__mainBody">
            <iframe style="height: 87vh;border: none; background: white;" v-if="toShowDashboard" id="myDash"
                :src="toShowDashboard" />
            <Modal class="modal-new-search2" v-if="showPulseModal" :show="showPulseModal">
                <PulseModalVue @close="showPulseModal = false; handlePulseReload($event)" :pulseType="pulseType"
                    :account_domain="accountDomain" :account_id="accountId" :selectedPulseOpen="0" />
            </Modal>

            <Modal class="modal-new-summary" v-if="openSumaryWidget" :show="openSumaryWidget">
                <SummaryNew @close="openSumaryWidget = false;" :domain="summaryParams['domain']" :id="summaryParams['id']" />
            </Modal>

            <Modal style="padding: 0px;" class="modal-new-search5" v-if="openNotes" :show="openNotes">
                <Notes @close="openNotes = false" :domain="accountDomain" :id="accountId" :appeqkey="appeqKey"
                    :appeqbusinessobject="appeqType" :crmbusinessobject="type" />
            </Modal>
            <Modal class="modal-new-search4" v-if="openPopupDash" :show="openPopupDash">
                <img @click="openPopupDash = false;handlePopupupClsoe()" style="position: absolute;right: 4px;top: 4px;"
                    src="../icons/crossButton.svg" />
                <iframe :src="dashboardLinkPopup" style="height: 100%;border: none;outline: none;margin-top: 20px;" />
            </Modal>
            <Modal class="modal-new-search" v-if="openTags" :show="openTags">
                <img @click="openTags = false" style="position: absolute;right: 4px;top: 4px;"
                    src="../icons/crossButton.svg" />
                <h5>Tags</h5>
                <Multiselect :taggable="true" @tag="addTag($event, index)" v-model="tag" :close-on-select="true"
                    placeholder="Select tags" :options="tags" />
                <button style="width: 90%;height: 30px;margin-top: 20px;" @click="handleSaveTag">Save</button>
            </Modal>
            <Modal class="modal-new-search3" v-if="showAccountModal" :show="showAccountModal">
                <NewTicketsVue :account_domain="accountDomain" :accountid="accountId"
                    @close="showAccountModal = false" />
            </Modal>
            <Modal class="modal-new-search6" v-if="openTasks" :show="openTasks">
                <img src="../icons/crossButton.svg"
                    style="height: 14px !important;cursor: pointer;position: absolute;right: 12px;top: 4px"
                    @click="openTasks = false" />
                <TaskPageVue :filterTasks="objectTasks" :filterCond="{}" @close="openTasks = false" />
            </Modal>
        </div>
        <div class="" v-else-if="showTemplates">
            <iframe style="height: 100vh;" id="template_metabase_iframe" :src="templatesDashIframeURL"
                frameborder="0"></iframe>
        </div>
        <div v-else-if="showTasks">
            <TaskPageVue :filterTasks="objectTasks" :filterCond="{}" @close="openTasks = false" />

        </div>
        <div v-else-if="showRed">
            <iframe :src=handleReds style="border: none;height: 85vh;" />
        </div>
        <div class="dashboard__footer">
            <div style="display: flex;align-items: center;justify-content: flex-end;" class="">
                Powered By <span>AppEQ.ai</span>
            </div>

        </div>
        <!-- <div v-if="afterLoadShowAi"
            style="position: absolute; bottom: 4%;left: 3%;height: 45px;width: 45px; border-radius: 50%; background: #5d5dff;">
            <img @click="getGPTInsights()" src="../assets/img/AI Icon.png"
                style="cursor: pointer; filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(311deg) brightness(195%) contrast(102%);"
                alt="">
        </div> -->
        <!-- <div @click="getGPTInsights()" v-if="afterLoadShowAi"
            style="position: absolute;bottom: 8%;left: 4.8%;height: 1.6%;width: 0.8%;border-radius: 50%;background: red; cursor: pointer;">
        </div> -->
        <div v-if="openDownloadModal"
            style="position: absolute; top: 7%; left: 45%; padding: 10px; border-radius: 10px; height: 6%; width: 12%; background-color: white; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
            <div
                style="display: flex; justify-content: space-between; align-items: center; flex-direction: column; height: 100%;">
                <div style="width: 100%;">
                    <div
                        style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 100%;">
                        <!-- <div style="color: blue;">{{ email }} </div> -->
                        <div class="color: blue;">
                            Download PDF
                        </div>
                        <div>
                            <img @click="handleSend" v-tooltip="'You will receive in your Inbox'"
                                src="../icons/sendIcon.svg" style="height: 22px; cursor: pointer;" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="opsnShareModal"
            style="position: absolute; top: 7%; left: 45%; padding: 10px; border-radius: 10px; height: 12%; width: 12%; background-color: white; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
            <div
                style="display: flex; justify-content: space-between; align-items: center; flex-direction: column; height: 100%;">
                <div style="width: 100%;">
                    <div class="">
                        <input v-model="shareEmail"
                            style="border: 1px solid #e8e8e8;width: 100%;border-radius: 8px;padding: 2px; font-size: 10px; height: 30px; outline: none;"
                            placeholder="Enter email" />
                    </div>
                    <div
                        style="display: flex; justify-content: space-between; align-items: center; width: 100%; height: 100%;">
                        <!-- <div style="color: blue;">{{ email }} </div> -->
                        <div class="color: blue;">
                            Share PDF
                        </div>
                        <div>
                            <img @click="handleShareSend" v-tooltip="'Send as email'" src="../icons/sendIcon.svg"
                                style="height: 22px; cursor: pointer;" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <Modal class="modal-email-search4" v-if="openEmailWidget" :show="openEmailWidget">
            <EmailForm :emailparams="emailParams" @close="openEmailWidget=false" />
        </Modal>
        <Modal class="modal-new-search" :show="showLoadingForMetabaseDash">
            <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <img src="../assets/img/preloader5.gif" style="height: 12vh;" alt="">
                <transition name="fade" mode="out-in">
                    <p :key="currentQuoteIndex" style="font-size: 10px;text-align: center;">
                        {{ loadingQuotes[currentQuoteIndex] }}
                    </p>
                </transition>
            </div>
        </Modal>
        <div v-if="showAiModal"
            style="position: absolute; bottom: 5%; left: 7%; padding: 10px; border-radius: 10px; height: 72%; width: 45%; background-color: aliceblue; box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
            <div
                style="display: flex; justify-content: space-between; align-items: center; flex-direction: column; height: 100%;">
                <div style="width: 100%;">
                    <div style="display: flex; justify-content: end; align-items: center;">
                        <img @click="handleCloseGenie()" src="../icons/Close.svg" style="height: 20px; cursor: pointer;"
                            alt="">
                    </div>
                    <div class="aiModal__header" style="display: flex;">
                        <div
                            style="display: flex; flex-direction: column; align-items: center; justify-content: space-around;">
                            <div style="background-color: #6b6bff; border-radius: 50%; width: 35px; height: 35px;">
                                <img style="height: 35px; filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(311deg) brightness(195%) contrast(102%);"
                                    src="../assets/img/AI Icon.png" alt="">
                            </div>
                            <div style=" color: #ffbc00; font-weight: 700; font-size: 12px;">
                                Beta
                            </div>
                        </div>
                        <div style="margin-left: 15px; width: 100%;">
                            <h4 style="color: #4d4d4d !important; font-size: 1rem; font-weight: 600; display: flex;">
                                Hi , I am Genie - your assistant.
                            </h4>
                            <div style="text-align: center;">
                                <span>Here are my callouts for this Dashboard</span>
                            </div>
                        </div>
                    </div>
                    <div class="aiModal__body">
                        <div v-if="!loadingInsights">
                            <pre
                                style="color: black; white-space: break-spaces; padding: 10px;">{{ typeWriterText }}</pre>
                        </div>
                        <div v-else style="display: flex; justify-content: center; align-items: center;">
                            <img style="height: 100px;" src="../icons/loadingripple.svg" alt="">
                        </div>
                    </div>
                    <div class="aiModal__footer">
                        <div></div>
                        <div style="display: flex; justify-content: center;">
                            <h4 style="color: #4d4d4d !important; font-size: 16px;">Was this useful ?</h4>
                            <div>
                                <img @click="handleActionForReview('like')"
                                    :style="{ 'filter': (liked == 1) ? 'invert(8%) sepia(87%) saturate(6915%) hue-rotate(247deg) brightness(102%) contrast(150%)' : '' }"
                                    src="../assets/img/like.png" v-tooltip="'Like it'"
                                    style="height: 16px; cursor: pointer; margin-right: 10px; margin-left: 10px; opacity: 0.5;"
                                    alt="">
                                <img @click="handleActionForReview('dislike')"
                                    :style="{ 'filter': (liked == 0) ? 'invert(8%) sepia(87%) saturate(6915%) hue-rotate(247deg) brightness(102%) contrast(150%)' : '' }"
                                    src="../assets/img/like.png" v-tooltip="'Dislike it'"
                                    style="height: 16px; cursor: pointer; transform: rotate(180deg); opacity: 0.5;"
                                    alt="">
                            </div>
                        </div>
                        <div v-if="!showTasks && !showTemplates">
                            <img @click="handleRegenerate" src="../icons/refresh.png"
                                style="height: 17px; margin-left: 25px; cursor: pointer;" v-tooltip="'Regenerate'"
                                alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Server from '../components/API/Server';
import { JWT } from "node-jsonwebtoken";
import Templates from "./Templates.vue"
import { createApp } from 'vue';

import Multiselect from 'vue-multiselect';
import AppEQAnalytics from "../analytics/appeqAnalytics"
// import Modal from '../components/Modal.vue';
import PulseModalVue from './PulseModal.vue';
import NewTicketsVue from '../NewTickets.vue';
import Vue from 'vue';
import EmailForm from "./EmailForm.vue"
// import PulseView from '../components/PulseView.vue';
import Notes from './Notes.vue';
import TaskPageVue from '../components/TaskPage.vue';
import AddTaskModal from '../components/AddTaskModal.vue';
import PulseView from '../components/PulseView.vue';
import Modal from '../components/Modal.vue';
import SummaryNew from './SummaryNew.vue';
// import SummaryNew from './SummaryNew.vue';
// import / from './EmailForm.vue';
// import EmailForm from './EmailForm.vue';
export default {
    components: {
        Templates,
        Modal,
        SummaryNew,
        PulseModalVue,
        Multiselect,
        EmailForm,
        NewTicketsVue,
        PulseView,
        TaskPageVue,
        Notes
    },
    data() {
        return {
            openNotes: false,
            openPopupDash: false,
            dashboardLinkPopup: "",
            accountDomain: "",
            accountId: "",
            tags: ["Add Tags"],
            tag: "",
            totalDashboards: [],
            appeqType: "",
            type: "",
            appeqKey: "",
            toShowDashboard: "",
            selectedDashboard: "",
            openSumaryWidget:false,
            summaryParams:{},
            openEmailWidget:false,
            templatesDashIframeURL: "",
            observer:null,
            emailParams:{},
            afterLoadShowAi: false,
            backButton: [],
            showLoadingForMetabaseDash: false,
            currentQuoteIndex: 0,
            loadingQuotes: [
                "Analyzing Customer Journeys for Your Success...",
                "Fetching Key Insights to Drive Customer Satisfaction...",
                "Preparing Data for Enhanced Client Relationships...",
                "Crafting Strategies for Account Growth...",
                "Loading Metrics to Elevate Your Performance..."
            ],
            gptInsights: ``,
            typeWriterText: ``,
            openTasks: false,
            i: 0,
            objectTasks: {},
            pulseType: "",
            showAccountModal: false,
            liked: null,
            showPulseModal: false,
            loadingInsights: false,
            showFullScreenIcon: true,
            showTemplates: false,
            openDownloadModal: false,
            setParams: null,
            opsnShareModal: false,
            showAiModal: false,
            reGenerate: false,
            dashNum: null,
            currentDashboard: {},
            editClicked: false,
            email: "",
            handleReds: "",
            shareEmail: "",
            openTags: false,
            showOptions: false,
            showRed: false,
            showTasks: false,
        }
    },
    methods: {
        handlePopupupClsoe(){
            
            const observeIframeContent = () => {
                try {
                    this.showLoadingForMetabaseDash = false;

                    const iframe = document.getElementById("myDash");
                    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    const targetNode = iframeDocument.body;
                    console.log("Setting up observer for iframe content");

                    // Check if there's an existing observer and disconnect it
                    if (this.observer) {
                        this.showLoadingForMetabaseDash = false;

                        this.observer.disconnect();
                        console.log("Previous observer disconnected");
                    }

                    // Create a new MutationObserver
                    this.observer = new MutationObserver((mutationsList, observer) => {
                        console.log("Observer detected changes in the iframe content");
                        this.handlingClicksInMetabaseDashboard("myDash");
                    });

                    // Start observing the target node for DOM changes
                    this.observer.observe(targetNode, { childList: true, subtree: true });

                } catch (e) {
                    console.log("Error setting up observer:", e);
                    // Retry after 100ms if there's an error
                    setTimeout(observeIframeContent, 100);
                }
            };
            observeIframeContent()
        },
        handleShowticket(){
      window.parent.postMessage({action:"__appeq_open_ticket_iframe"}, "*");

        },
        handleEditRed() {
            window.open("https://app.appeq.ai/early-warning")
        },
        async handleRedCondition() {
            this.handleReds = await this.openDashPopup({ metabaseNumber: 517 })
            this.showRed = true
            this.showTasks = false;
            this.showTemplates = false
        },
        checkIfAppeq() {
            return this.email.split("@")[1] == 'appeq.ai'
        },
        handleShowTasks() {
            this.objectTasks = {
                assignee: localStorage.getItem("__email__id"),
                status: "open"
            }
            this.showTasks = true
            this.showTemplates=false;
            this.showRed=false
            this.afterLoadShowAi = false
        },
        addTag(newTag, index) {
            const parts = newTag.split(', ');

            const tag = newTag;
            this.tags.push(tag);
            // this.selectedBObjecte.push(tag)
        },
        async handleSaveTag() {
            try {
                const data = await Server.saveTags(this.objectTasks, this.tag)
                if (data.code == 200) {
                    this.$toasted.success(data.msg)
                } else {
                    this.$toasted.error(data.msg)

                }


            } catch (error) {
                this.$toasted.error(error)

            }
        },

        handlePulseReload(ev) {
            if (ev && ev.action) {
                // this.openDashForThis(this.currentDashboard)
                const accId = this.accountId
                const accDomain = this.accountDomain
                const message = { type: 'appeq_pulse_reload', data: { domain: accDomain, id: accId } };
                window.parent.postMessage(message, '*'); // '*' can be replaced with a specific origin for better security

            }

        },
        handleReload() {
            this.openDashForThis(this.currentDashboard)
        },
        async handleBack() {
            // if(!dashParams){
            //                         this.backButton.push({metabaseNumber:dashNum})
            //                         await this.openDashForThis({metabaseNumber:dashNum})
            //                     }else{
            //                         this.backButton.push({metabaseNumber:dashNum,customCode:dashParams})
            //                         await this.openDashForThis({metabaseNumber:dashNum, customCode: dashParams})
            //                     }
            console.log(this.backButton)
            if (this.backButton.length > 0) {
                const dashNum = this.backButton[this.backButton.length - 2]['metabaseNumber']
                const dashParams = this.backButton[this.backButton.length - 2]['customCode']
                this.backButton.splice(this.backButton.length - 2, 2);
                if (dashParams)
                    await this.openDashForThis({ metabaseNumber: dashNum, customCode: dashParams })
                else
                    await this.openDashForThis({ metabaseNumber: dashNum })

            }
        },
        handlePhoneView() {
            window.parent.postMessage({ action: "__appeq_phone_view_dashboard" }, "*");
            AppEQAnalytics.actionCall(`PhoneViewClicked`, "Dashboard", {})

        },
        handleGotoFullScreen() {
            window.parent.postMessage({ action: "__allow_dashboard_fullscreen" }, "*");
            this.showFullScreenIcon = false;
        },
        exitFullScreen() {
            window.parent.postMessage({ action: "__exit_dashboard_fullscreen" }, "*");
            this.showFullScreenIcon = true;
        },
        handlePcView() {
            window.parent.postMessage({ action: "__appeq_pc_view_dashboard" }, "*");
            AppEQAnalytics.actionCall(`PcViewClicked`, "Dashboard", {})

        },
        handleHomeClick() {
            if (this.totalDashboards.length == 1) {
                this.openDashForThis(this.totalDashboards[0]);
            }
        },
        handleCloseGenie() {
            this.showAiModal = false;
            this.gptInsights = ``;
            this.typeWriterText = ``;
            this.i = 0;
            this.liked = null;
        },
        async handleRegenerate() {
            this.showAiModal = false;
            this.gptInsights = ``;
            this.typeWriterText = ``;
            this.i = 0;
            this.liked = null;
            await this.getGPTInsights('refresh');
        },
        async getGPTInsights(mode) {
            this.showAiModal = !this.showAiModal;
            if (this.showAiModal == false) {
                this.gptInsights = ``;
                this.typeWriterText = ``;
                this.i = 0;
                this.liked = null;
                return;
            }
            this.loadingInsights = true;
            try {
                let res = await Server.getGPTInsightsAboutDashboard(this.selectedDashboard, this.email, mode);
                console.log(res);
                this.gptInsights = res.data.message.content;
                this.loadingInsights = false;
                await this.typeWriter();
                AppEQAnalytics.actionCall("GenieInsightsReadingDashboardOpened", "Dashboard", {})
            } catch (error) {
                console.log(error);
                this.$toasted.error('Some error occured');
                this.loadingInsights = false;
            }
        },
        async typeWriter() {
            if (this.i < this.gptInsights.length) {
                this.typeWriterText += this.gptInsights.charAt(this.i);
                this.i++;
                const wait = (ms) => new Promise((res) => setTimeout(res, ms))
                await wait(20);
                return await this.typeWriter()
            }

        },
        async handleActionForReview(status) {
            if (status == 'like') {
                this.liked = 1;
                AppEQAnalytics.actionCall("GenieInsightsReadingDashboardLiked", "Dashboard", { dashboard_num: this.selectedDashboard })
            } else if (status == 'dislike') {
                this.liked = 0;
                AppEQAnalytics.actionCall("GenieInsightsReadingDashboardDisliked", "Dashboard", { dashboard_num: this.selectedDashboard })
            }
        },
        async handleShare() {
            try {
                let res = await Server.sendDashboardInEmail(this.email, this.dashNum, this.setParams);
                console.log(res, 'mail resp');
                this.openDownloadModal = false;
                this.$toasted.success('You will recieve the mail after the download is finished!');
            } catch (error) {
                this.openDownloadModal = false;
                this.$toasted.error('Some error occured.')
            }
        },
        isValidEmail(email) {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(email);
        },
        async handleShareSend() {
            try {
                if (this.shareEmail.split('@').length == 2 && this.shareEmail.split('@')[1] === this.email.split('@')[1] && this.isValidEmail(this.shareEmail)) {
                    let res = await Server.sendDashboardInEmail(this.shareEmail, this.dashNum, this.setParams);
                    console.log(res, 'mail resp');
                    this.opsnShareModal = false;
                    this.$toasted.success(`${this.shareEmail} will recieve the mail after the download is finished!`);
                } else {
                    this.$toasted.error('Enter email in same domain.');
                }
            } catch (error) {
                this.opsnShareModal = false;
                this.$toasted.error('Some error occured.')
            }
        },
        async handleSend() {
            try {
                let res = await Server.sendDashboardInEmail(this.email, this.dashNum, this.setParams);
                console.log(res, 'mail resp');
                this.openDownloadModal = false;
                this.$toasted.success('You will recieve the mail after the download is finished!');
            } catch (error) {
                this.openDownloadModal = false;
                this.$toasted.error('Some error occured.')
            }
        },
        async handleShowTemplates() {
            this.opsnShareModal = false;
            this.openDownloadModal = false;
            this.showAiModal = false;
            this.afterLoadShowAi = !this.afterLoadShowAi;
            this.showTemplates = true

            this.showTasks = false
            const wait = (ms) => new Promise((res) => setTimeout(res, ms));
            this.templatesDashIframeURL =  await this.openDashPopup({ metabaseNumber: 426,customCode:JSON.stringify({
                "email":localStorage.getItem("__email__id")
            } )})

            await wait(100);
            if (document.getElementById("template_metabase_iframe")) {
                this.showLoadingForMetabaseDash = true;
                document.getElementById("template_metabase_iframe").addEventListener("load", (ev) => {
                    const iframe = document.getElementById("template_metabase_iframe");

                    setTimeout(this.handlingClicksInMetabaseDashboard("template_metabase_iframe"), 200);
                });

            }
            AppEQAnalytics.actionCall("TemplatesDashboardButtonClicked", "Dashboard", {})
        },
        async handleGenerate() {
            console.log('working');
        },
        getObjectKVfromQuery(keyVals) {
            let keyValStr = keyVals.toString();
            let obj = {};
            if (keyValStr.includes('&')) {
                let keyValArr = keyValStr.split('&');
                for (let i = 0; i < keyValArr.length; i++) {
                    let first = keyValArr[i].split('=')[0];
                    let second = keyValArr[i].split('=')[1];
                    second = second.replace(/%20/g, ' '); // Replace %20 with space
                    obj[first] = second;
                }
            } else {
                let first = keyValStr.split('=')[0];
                let second = keyValStr.split('=')[1];
                second = second.replace(/%20/g, ' ');
                obj[first] = second;
            }
            return JSON.stringify(obj);
        },
        handlingClicksInMetabaseDashboard(id) {
            try {

                const links = document.getElementById(id).contentDocument.querySelectorAll('a');
                // console.log(links)
                this.showLoadingForMetabaseDash = false;
                try {


                } catch (error) {

                }
                links.forEach(link => {
                    if (link && link.textContent.includes("appeq.ai/wp-content")) {
                        const img = document.createElement('img');
                        img.src = link.textContent; // Set the image source to the link's href
                        img.alt = 'Image';
                        img.style.height = "20px"
                        link.innerHTML = ''; // Clear the link text
                        link.appendChild(img); // Add the image to the link
                    }
                    if (link && link.textContent.includes("appeq__pulse_widget")) {
                        try {

                            const someD = link.href && link.href.includes("?")?link.href:link.textContent 
                            link.innerHTML = '';
                            const widgetDiv = document.createElement('div');

                            try {
                                console.log(link.href)
                                console.log(link.textContent)
                                const newData = someD.split("?")
                                if (newData.length > 1) {
                                    const paramsD = JSON.parse(this.getObjectKVfromQuery(newData[1]));
                                    console.log(paramsD)
                                    widgetDiv.id = `pulse-widget-${paramsD.account_domain}-${paramsD.account_id}`;  // Create a unique ID using the params
                                    link.appendChild(widgetDiv);

                                    new Vue({
                                        render: h => h(PulseView, {
                                            props: {
                                                domain: paramsD.account_domain,
                                                id: paramsD.account_id
                                            }
                                        })
                                    }).$mount(widgetDiv);
                                    link.addEventListener("click", (ev) => {
                                        ev.preventDefault()
                                        this.accountDomain = paramsD['account_domain']
                                        this.accountId = paramsD['account_id']
                                        this.pulseType = paramsD['pulseType']
                                        console.log(this.accountDomain, this.accountId)
                                        this.showPulseModal = true;
                                        // const pulseViewComponent = widgetDiv.__vue__.$refs.pulseViewRef;
                                        // if (pulseViewComponent) {
                                        //     pulseViewComponent.refreshData();  // This triggers the refreshData method in PulseView
                                        // }
                                    })

                                    // Create a new Vue instance with the component
                                }
                            } catch (error) {
                                console.error('Error mounting Vue component:', error);
                            }


                        } catch (error) {
                            console.log("error in ")
                            console.log(error)
                        }
                    }

                    link.addEventListener('click', async (event) => {

                        // Prevent the default action
                        const arr = event.target.href.split("/")
                        // metabaseTitle.appeq.ai/sadsad?asdsad=200
                        let urlSecondPart = arr[arr.length - 1]
                        let secondLastPart = arr[arr.length - 2]
                        console.log(secondLastPart, urlSecondPart)
                        let dashNum = null;
                        let dashParams = null;
                        if (secondLastPart == "dashboard") {
                            event.preventDefault();

                            if (urlSecondPart.includes('?')) {
                                let queryArr = urlSecondPart.split('?');
                                dashNum = queryArr[0];

                                dashParams = this.getObjectKVfromQuery(queryArr[1]);
                            } else {
                                dashNum = arr[arr.length - 1]
                            }
                            if (!dashParams) {
                                // this.backButton.push({metabaseNumber:dashNum})
                                await this.openDashForThis({ metabaseNumber: dashNum })
                            } else {
                                await this.openDashForThis({ metabaseNumber: dashNum, customCode: dashParams })
                            }
                            AppEQAnalytics.actionCall(`DashboardRedirected`, "Dashboard", {})

                        } else if (secondLastPart == "feeds") {
                            event.preventDefault();

                            let queryArr = urlSecondPart.split('?');
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));

                            this.accountDomain = obj['domain']
                            this.accountId = obj['id']
                            this.showAccountModal = true

                            // window.parent.postMessage({ action: "__appeq_close_all_dashboard" }, "*");
                            // window.parent.postMessage({ action: "__appeq_open_ticket_iframe",val:true }, "*");
                        } else if (secondLastPart == "pulse") {
                            event.preventDefault();
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            console.log(obj)
                            this.accountDomain = obj['account_domain']
                            this.accountId = obj['account_id']
                            this.pulseType = obj['pulseType']
                            console.log(this.accountDomain, this.accountId)
                            this.showPulseModal = true;
                            AppEQAnalytics.actionCall(`Pulseclicked`, "Dashboard", {})

                        } else if (secondLastPart == "dashboard-popup") {
                            event.preventDefault();
                            if (urlSecondPart.includes('?')) {
                                let queryArr = urlSecondPart.split('?');
                                dashNum = queryArr[0];

                                dashParams = this.getObjectKVfromQuery(queryArr[1]);
                            } else {
                                dashNum = arr[arr.length - 1]
                            }
                            console.log(dashNum)
                            this.observer.disconnect()

                            this.dashboardLinkPopup = await this.openDashPopup({ metabaseNumber: dashNum, customCode: dashParams })
                            this.openPopupDash = true
                           
                            AppEQAnalytics.actionCall(`DashboardPopupClicked`, "Dashboard", {})


                        } else if (secondLastPart == "notes") {
                            event.preventDefault()
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            console.log(obj)
                            this.accountDomain = obj['account_domain']
                            this.accountId = obj['account_id']
                            this.appeqType = obj['appeq_type']
                            this.type = obj['type']
                            this.appeqKey = obj['key']
                            console.log(this.appeqKey)
                            this.openNotes = true
                            // AppEQAnalytics.actionCall()
                            AppEQAnalytics.actionCall(`NotesClicked`, "Dashboard", {})

                            // window.parent.postMessage({ action: "__appeq_open_notes_iframe_summary", data:{account_domain: this.accountDomain, id: this.accountId, state: "public" }}, "*");
                            // feeds.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.accountid, state: 'private' })

                        } else if (secondLastPart == "tasks") {
                            event.preventDefault()
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            console.log(obj)
                            this.accountDomain = obj['account_domain']
                            this.accountId = obj['account_id']
                            this.objectTasks = obj
                            this.openTasks = true
                            AppEQAnalytics.actionCall(`TaskClicked`, "Dashboard", {})

                        } else if (secondLastPart == "set-tags") {
                            event.preventDefault()
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            console.log(obj)
                            this.objectTasks = obj
                            const tagsServer = (await Server.gettags(obj['business_object_name'])).grouped_tags.tags
                            if (tagsServer && tagsServer.length != 0) {
                                this.tags = tagsServer
                            }
                            this.openTags = true
                        } else if (secondLastPart == 'appeq__pulse_widget') {
                            event.preventDefault()
                        }else if(secondLastPart == 'email-widget'){
                            event.preventDefault()
                            this.openEmailWidget=true;
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            this.emailParams= obj

                        }else if(secondLastPart=='summary-widget'){
                            event.preventDefault()
                            let queryArr = urlSecondPart.split('?');
                            console.log(queryArr)
                            let obj = JSON.parse(this.getObjectKVfromQuery(queryArr[1]));
                            this.summaryParams= obj
                            this.openSumaryWidget=true;

                        }
                        // window.location.href=
                        // clearInterval()

                    });
                });


            } catch (error) {

            }
        },
        startQuoteRotation() {
            setInterval(() => {
                this.currentQuoteIndex = (this.currentQuoteIndex + 1) % this.loadingQuotes.length;
            }, 3000);
        },
        async openDashForThis(dash) {
            this.showTemplates = false
            this.showTasks = false
            this.showRed = false
            this.toShowDashboard = ""
            this.showLoadingForMetabaseDash = true;
            if (dash && this.backButton.filter(d => d.metabaseNumber == dash.metabaseNumber).length == 0)
                this.backButton.push(dash);
            this.currentDashboard = dash
            await this.openDash(dash)
            ; // Declare the observer in a broader scope

            const observeIframeContent = () => {
                try {
                    this.showLoadingForMetabaseDash = false;

                    const iframe = document.getElementById("myDash");
                    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    const targetNode = iframeDocument.body;
                    console.log("Setting up observer for iframe content");

                    // Check if there's an existing observer and disconnect it
                    if (this.observer) {
                        this.showLoadingForMetabaseDash = false;

                        this.observer.disconnect();
                        console.log("Previous observer disconnected");
                    }

                    // Create a new MutationObserver
                    this.observer = new MutationObserver((mutationsList, observer) => {
                        console.log("Observer detected changes in the iframe content");
                        this.handlingClicksInMetabaseDashboard("myDash");
                    });

                    // Start observing the target node for DOM changes
                    this.observer.observe(targetNode, { childList: true, subtree: true });

                } catch (e) {
                    console.log("Error setting up observer:", e);
                    // Retry after 100ms if there's an error
                    setTimeout(observeIframeContent, 100);
                }
            };
            // observeIframeContent()


            // Attach the load event to the iframe to reinitialize the observer every time it loads
            document.getElementById("myDash").addEventListener("load", () => {
                console.log("Iframe reloaded, reinitializing observer");
                setTimeout(() => {
                    this.handlingClicksInMetabaseDashboard("myDash")

                }, 1200);
                // setTimeout(() => {
                //     // this.handleAddActionItems("myDash")

                // }, 2000);
                setTimeout(observeIframeContent, 20); // Slight delay to ensure content is ready
                document.getElementById("myDash").addEventListener("click", (ev) => {
                    this.handlingClicksInMetabaseDashboard("myDash")
                })
            });

            const metabaseTitle = dash.metabaseTitle;
            const formattedTitle = metabaseTitle ? metabaseTitle.replace(/\s+/g, '') : "Dashboard";
            AppEQAnalytics.actionCall(`${formattedTitle}Opened`, "Dashboard", { dashboard_num: (dash.metabaseNumber ? dash.metabaseNumber : '67') })
        },
        closeDash() {
            window.parent.postMessage({ action: "__appeq_close_all_dashboard" }, "*");
            AppEQAnalytics.actionCall(`CloseDashboardClicked`, "Dashboard", {})

        },
        handleAddActionItems(id) {
            document.getElementById(id).contentDocument.querySelectorAll(".saving-dom-image-hidden").forEach(el => {
                console.log(el);
                el.addEventListener("click", (ev) => {
                    setTimeout(() => {
                        const contentDocument = document.getElementById(id).contentDocument;

                        if (contentDocument.querySelectorAll(".emotion-Popover-dropdown").length != 0) {
                            contentDocument.querySelectorAll(".emotion-Popover-dropdown").forEach(list => {
                                const olElement = list.querySelector('ol');

                                if (olElement) {
                                    // Find the existing <li> element to copy
                                    const existingListItem = olElement.querySelector('li');

                                    if (existingListItem) {
                                        // Clone the existing <li> element
                                        const newListItem = existingListItem.cloneNode(true);
                                        const image=document.createElement("img")
                                        image.src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Refresh_icon.png"
                                        image.style=`
                                            height:14px;
                                            width:14px;
                                            cursor:pointer;
                                        `
                                        newListItem.appendChild(image)
                                        // Modify the text content of the new <li>
                                        newListItem.textContent = 'Sync to Spreadsheet'; // Change text as needed
                                        newListItem.class ='emotion-unxtz'
                                        // Append the new <li> to the <ol>
                                        olElement.appendChild(newListItem);
                                    } else {
                                        console.error('No <li> element found to copy.');
                                    }
                                } else {
                                    console.error('No <ol> element found within the specified class.');
                                }
                            });
                        }
                    }, 1000);
                });
            });
        },

        async openDashPopup(dash) {
            console.log(dash)
            let email = localStorage.getItem('__email__id')

            let metabaseNumber = '';

            let customCode = '';
            if (dash && dash.metabaseNumber) {
                metabaseNumber = Number(dash.metabaseNumber);
                this.selectedDashboard = metabaseNumber
                customCode = dash.customCode;
            }
            let params = {};
            if (customCode && JSON.parse(customCode)) {
                let resp = JSON.parse(customCode);
                params = resp
                console.log(resp);
                Object.keys(resp).map((d) => {
                    if (resp[d].includes("{{")) {
                        resp[d] = resp[d].replace("{{", "");
                    }
                    if (resp[d].includes("}}")) {
                        resp[d] = resp[d].replace("}}", "");
                    }
                    if (resp[d].includes("email_id")) {
                        params[d] = localStorage.getItem("__email__id");

                    }
                    if (resp[d].includes("name")) {
                        params[d] = localStorage.getItem("__email__id").split("@")[0];

                    }
                    if (resp[d].includes("TODAY()")) {
                        const currDate = new Date()
                        if (resp[d].includes("-")) {
                            const val = parseInt(resp[d].split("-")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        if (resp[d].includes("+")) {
                            const val = parseInt(resp[d].split("+")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        params[d] = currDate.toISOString()

                    }

                })
                console.log(resp);
            }
            if (!metabaseNumber) {
                metabaseNumber = 67;
            }
            this.dashNum = metabaseNumber;
            // if (userInfo && userInfo.data && userInfo.data.org_id)
            //     this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const currentDate = new Date();
            // Add two days to the current date
            currentDate.setDate(currentDate.getDate() + 2);
            console.log(params)
            if (metabaseNumber !== 67) {
                this.setParams = params;
            } else {
                this.setParams = {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                };
            }
            const token = await jwt.sign({
                resource: { dashboard: metabaseNumber },
                params: (metabaseNumber !== 67) ? params : {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            return iframeUrl
        },
        async openDash(dash) {
            console.log(dash)
            // const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data
            let email = localStorage.getItem('__email__id')

            let metabaseNumber = '';

            let customCode = '';
            if (dash && dash.metabaseNumber) {
                metabaseNumber = Number(dash.metabaseNumber);
                this.selectedDashboard = metabaseNumber
                customCode = dash.customCode;
            }
            let params = {};
            if (customCode && JSON.parse(customCode)) {
                let resp = JSON.parse(customCode);
                params = resp
                console.log(resp);
                Object.keys(resp).map((d) => {
                    if (resp[d].includes("{{")) {
                        resp[d] = resp[d].replace("{{", "");
                    }
                    if (resp[d].includes("}}")) {
                        resp[d] = resp[d].replace("}}", "");
                    }
                    if (resp[d].includes("email_id")) {
                        params[d] = localStorage.getItem("__email__id");

                    }
                    if (resp[d].includes("TODAY()")) {
                        const currDate = new Date()
                        if (resp[d].includes("-")) {
                            const val = parseInt(resp[d].split("-")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        if (resp[d].includes("+")) {
                            const val = parseInt(resp[d].split("+")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        params[d] = currDate.toISOString()

                    }

                })
                console.log(resp);
            }
            if (!metabaseNumber) {
                metabaseNumber = 67;
            }
            this.dashNum = metabaseNumber;
            // if (userInfo && userInfo.data && userInfo.data.org_id)
                // this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const currentDate = new Date();
            // Add two days to the current date
            currentDate.setDate(currentDate.getDate() + 2);
            console.log(params)
            if (metabaseNumber !== 67) {
                this.setParams = params;
            } else {
                this.setParams = {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                };
            }
            const token = await jwt.sign({
                resource: { dashboard: metabaseNumber },
                params: (metabaseNumber !== 67) ? params : {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";

            this.toShowDashboard = iframeUrl
            console.log(iframeUrl)
            this.afterLoadShowAi = true;


        },
        async downloadPdf() {
            const iframe = document.getElementById('myDash');
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const { jsPDF } = window.jspdf;

            // Wait for iframe content to fully load
            await new Promise(resolve => {
                if (iframeDocument.readyState === 'complete') {
                    resolve();
                } else {
                    iframe.onload = resolve;
                }
            });

            // Use jsPDF to create PDF document
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = pdf.internal.pageSize.getWidth();

            // Function to capture and add content to PDF
            async function captureAndAddContent() {
                const canvas = await html2canvas(iframeDocument.body, {
                    useCORS: true,
                    scrollY: -iframe.contentWindow.scrollY,
                    scale: 1 // Increase scale for better quality if needed
                });

                const imgData = canvas.toDataURL('image/png');
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, canvas.height * (imgWidth / canvas.width));
            }

            // Capture iframe content and add to PDF
            await captureAndAddContent();

            // Save the PDF
            pdf.save('smartdashboardexport.pdf');
            AppEQAnalytics.actionCall("DownloadDashboardButtonClicked", "Dashboard", {
                dashboard_num: (this.totalDashboards[0].metabaseNumber ? this.totalDashboards[0].metabaseNumber : '67')
            })
        }
        ,
        getParamsByKey() {
            const queryString = window.location.search.slice(1); // Remove the leading '?'
            const params = new URLSearchParams(queryString);

            const filteredParams = {};
            params.forEach((key, val) => {
                console.log(key, val)
                filteredParams[val] = key;
            });

            return filteredParams;
        },

    },
    async mounted() {
        this.startQuoteRotation();
        const params = this.getParamsByKey()
        if (params && params['appeq_token']) {
            const jwt = new JWT("appeq_secret_key");
            const object = await jwt.verify(params['appeq_token']);
            console.log(object, "object")
            if (object && object['email']) {
                localStorage.setItem("__email__id", object['email'])

            }
            
        }
        this.email = localStorage.getItem('__email__id');
        Intercom('update', {
            "hide_default_launcher": false,
            // email:localStorage.getItem("__email__id")
        });
        this.showLoadingForMetabaseDash = true;
        let data = (await Server.getAllowedApps2(localStorage.getItem("__email__id"))).data;
        if (data && data.data && data.data.dashboards) {
            let dashboardsTotal = data.data.dashboards
            this.totalDashboards = dashboardsTotal

            // First, check if there's any dashboard that has a 'users' key and includes the logged-in user's email
            const userSpecificDashboards = this.totalDashboards.filter(d => (d.users && Array.isArray(d.users) && d.users.includes(this.email)) );

            // If there are user-specific dashboards, show only those
            if (userSpecificDashboards.length > 0) {
                this.totalDashboards = userSpecificDashboards;
            } else {
                // If there are no user-specific dashboards, show all dashboards without a 'users' key
                this.totalDashboards = [ {
      "metabaseNumber": "527",
      "customCode": "{\"email\":\"{{email_id}}\",\"name\":\"{{name}}\"}",
      "metabaseTitle": "Executive Dashboard",
      "metabasePic": "dashboard.png",
      "profileSummary": ""
            }]
            }
            if(params && params['tasks']){
            // this.showTasks=true;
            this.showLoadingForMetabaseDash=false

                this.handleShowTasks()
                return
            }


            // await this.openDash((this.totalDashboards[0]))
            console.log(this.totalDashboards)
            await this.openDashForThis(this.totalDashboards[0]);
            AppEQAnalytics.actionCall("SmartDashboardOpened", "Dashboard", {
                dashboard_num: (this.totalDashboards[0].metabaseNumber ? this.totalDashboards[0].metabaseNumber : '67')
            })

        }else{
            let dashboardsTotal = [
            {
      "metabaseNumber": "527",
      "customCode": "{}",
      "metabaseTitle": "Executive Dashboard",
      "metabasePic": "dashboard.png",
      "profileSummary": ""
            },
            ]
            this.totalDashboards = dashboardsTotal

            console.log(this.totalDashboards)
            if(params && params['tasks']){
            // this.showTasks=true;
            this.showLoadingForMetabaseDash=false
                this.handleShowTasks()
                return
            }
            await this.openDashForThis(this.totalDashboards[0]);
            AppEQAnalytics.actionCall("SmartDashboardOpened", "Dashboard", {
                dashboard_num: (this.totalDashboards[0].metabaseNumber ? this.totalDashboards[0].metabaseNumber : '67')
            }) 
        }
    }
}
</script>
<style lang="scss" scoped>
.dashboard {
    &__header {
        padding: 12px;
        background-color: #F1F3FF;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--title {
            span {
                color: rgb(84, 84, 84);
                font-weight: 700;
                font-size: 0.8vw;
            }

            img {
                height: 20px;
                margin-right: 4px;
                cursor: pointer;
            }
        }

        &--end {
            display: flex;
            align-items: center;

            img {
                height: 20px;
                cursor: pointer;
                margin-right: 10px;

            }
        }

        &--icons {
            display: flex;
            align-items: center;

            position: relative;

            &:nth-child(1n) {
                margin-left: 0px !important;
            }

            img {
                margin-left: 10px;
                height: 20px;
                cursor: pointer;


            }
        }
    }

    &__footer {
        padding: 12px;
        font-size: 8px;
        background-color: #F1F3FF;

        span {
            margin-left: 4px;
            color: blue;
            cursor: pointer;
        }
    }
}

.moreDashboards {
    display: none;
    position: absolute;
    top: 20px;
    left: 0px;
    height: 180px;
    width: 160px;
    padding: 10px;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
}

.dropdown-container {
    position: relative;
}

.dropdown-container:hover .moreDashboards,
.moreDashboards:hover {
    display: block;
}

.aiModal__body {
    height: 50vh;
    overflow-y: scroll;
    background: white;
    border-radius: 2px;
    margin: 10px;
}

.aiModal__footer {
    text-align: center;
    display: flex;
    justify-content: space-between;
}

.aiModal__footer__button {
    height: 35px;
    width: 150px;
    border-radius: 7px;
    background-color: #7979ff;
    color: white;
    outline: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
<style>
.html2canvas-container {
    width: 3000px !important;
    height: 3000px !important;
}

.modal-new-search {
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 200px;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-dialog-centered {
        justify-content: center;
    }
}

.modal-new-search2 {
    .modal-content {
        min-height: 250px;
        min-width: 114px;
        width: 65vw;
        height: 75vh;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 600px;
    }
}

.modal-new-search3 {
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 90vw;
        height: 90vh;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vh;
    }
}

.modal-new-search5 {
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 90vw;
        height: 90vh;
    }

    .modal-body {
        padding: 0px !important;
        height: 100%;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vh;
    }
}


.modal-new-search4 {
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 90vw;
        height: 90vh;
    }

    .modal-body {
        padding: 10px;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vw;
        max-height: 100vh;
    }
}

.modal-new-search6 {
    .modal-body {
        padding: 0px !important;
        height: 100%;
    }

    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 90vw;
        height: 90vh;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vw;
        max-height: 100vh;
    }
}
.modal-email-search4{
    
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 90vw;
        height: 90vh;
    }

    .modal-body {
        padding: 0px !important;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vw;
        max-height: 100vh;
    }
}
.modal-new-summary{
    .modal-content {
        min-height: 180px;
        min-width: 114px;
        width: 95vw;
        height: 90vh;
    }

    .modal-body {
        padding: 0px !important;
    }

    .modal-dialog-centered {
        justify-content: center;
    }

    .modal-dialog {
        max-width: 100vw;
        max-height: 100vh;
    }
}
</style>

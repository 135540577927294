import {JWT} from "node-jsonwebtoken"
class LocalUtils {
  //returns the email id of the logged in user use this here to get ADMIN_CREDS
  getEmail = () => {
    return localStorage.getItem("ADMIN_CREDS")
      ? JSON.parse(localStorage.getItem("ADMIN_CREDS"))["__email__id"]
      : null;
  };
  generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  generateSalesforceId = () => {
    const idLength = 12;
    return this.generateRandomString(idLength);
  };

  openDash=async (dash)=> {
    console.log(dash)
    // const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data
    let email = localStorage.getItem('__email__id')
  
    let metabaseNumber = '';
  
    let customCode = '';
    if (dash && dash.metabaseNumber) {
        metabaseNumber = Number(dash.metabaseNumber);
        this.selectedDashboard = metabaseNumber
        customCode = dash.customCode;
    }
    let params = {};
    if (customCode && JSON.parse(customCode)) {
        let resp = JSON.parse(customCode);
        params = resp
        console.log(resp);
        Object.keys(resp).map((d) => {
            if (resp[d].includes("{{")) {
                resp[d] = resp[d].replace("{{", "");
            }
            if (resp[d].includes("}}")) {
                resp[d] = resp[d].replace("}}", "");
            }
            if (resp[d].includes("email_id")) {
                params[d] = localStorage.getItem("__email__id");
  
            }
            if (resp[d].includes("TODAY()")) {
                const currDate = new Date()
                if (resp[d].includes("-")) {
                    const val = parseInt(resp[d].split("-")[1])
                    currDate.setDate(currDate.getDate() - val)
                }
                if (resp[d].includes("+")) {
                    const val = parseInt(resp[d].split("+")[1])
                    currDate.setDate(currDate.getDate() - val)
                }
                params[d] = currDate.toISOString()
  
            }
  
        })
        console.log(resp);
    }
    if (!metabaseNumber) {
        metabaseNumber = 67;
    }
    // this.dashNum = metabaseNumber;
    // if (userInfo && userInfo.data && userInfo.data.org_id)
        // this.org_id = userInfo.data.org_id
    var METABASE_SITE_URL = "https://app.appeq.ai/bi";
    var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
    const jwt = new JWT(METABASE_SECRET_KEY);
    const currentDate = new Date();
    // Add two days to the current date
    currentDate.setDate(currentDate.getDate() + 2);
    console.log(params)
    if (metabaseNumber !== 67) {
        this.setParams = params;
    } else {
        this.setParams = {
            appeq_org_id: this.org_id,
            current_date: currentDate.toISOString()
        };
    }
    const token = await jwt.sign({
        resource: { dashboard: metabaseNumber },
        params: (metabaseNumber !== 67) ? params : {
            appeq_org_id: this.org_id,
            current_date: currentDate.toISOString()
        },
        exp: Math.round(Date.now() / 1000) + (10 * 60)
    });
    var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
  
    // this.toShowDashboard = iframeUrl
    console.log(iframeUrl)
    return iframeUrl
    // this.afterLoadShowAi = true;
  
  
  }
}

export default new LocalUtils();

<template>
    <div style="padding: 12px">
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <h4 style="text-align: center;">Account Pulse for {{ accountName }}</h4>
            <img @click="closePopup" style="cursor: pointer; position: absolute; top: 25px; right: 25px;"
                src="../icons/Close.svg" />
        </div>
        <!-- <span style="font-weight: 450;text-align: center;text-transform: capitalize;">
            {{ pulseInfo[0]['account_domain'] }}
           </span>  -->

        <span v-if="viewPulse == true">
            <span>
                <div style="display: flex;align-items: center;border: 1px solid #e8e8e8;padding: 10px; height: 85px; justify-content: center;"
                    class="trends">
                    <div v-if="loading" style="display: flex; align-items: center; justify-content: center;"><img
                            src="../icons/loadingripple.svg" style="height: 100px;" alt=""></div>
                    <div @click="selectedPulse = index;" v-if="!loading"
                        :style="{ background: info.health, boxShadow: selectedPulse == index ? 'white 0px 0px 0px 2px, black 0px 0px 0px 3x' : ''
            ,border:selectedPulse == index?'1px solid purple':''


                         }"
                        style="width: 20px;height: 80px;margin-left: 10px;cursor: pointer;"
                        v-for=" info, index of pulseInfo">
                    </div>
                    <span v-if="pulseInfo.length == 0 && !loading" style="display: block; margin: auto;">
                        <span style="
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
" v-if="pulseInfo.length == 0 && !loading">
                            <img alt="" style="height: 30px; margin: 5px 41px; cursor: pointer;"
                                src="../icons/add-round.svg" @click="viewPulse = false;">
                            <div style="font-size: 14px; font-weight: 450;">No Account Pulse yet. <span
                                    style="color: blue;cursor: pointer;" @click="viewPulse = false;checkHandle()">Set now</span></div>
                        </span>
                        <!-- <span v-if="pulseInfo.length!=0">
                        <img alt="" style="height: 30px; margin: 5px 64px; cursor: pointer;" src="../icons/add-round.svg" @click="viewPulse=false;">
                        <div style="font-size: 14px; font-weight: 450;">You Can Add More Pulse</div>
                    </span> -->
                    </span>
                </div>
                <br />
                <span v-if="pulseInfo.length != 0" style="font-weight: 700;font-size: 15px;">
                    Reason: {{ selectedPulse != -1 ? pulseInfo[selectedPulse]['reason'] : '' }}

                </span>
                <br />
                <span v-if="pulseInfo.length != 0" style="font-weight: 400;font-size: 12px;">
                    {{ selectedPulse != -1 ? pulseInfo[selectedPulse]['description'] : '' }}

                </span>
                <br />
                <div class="" style="text-align: right;">
                    <span v-if="pulseInfo.length != 0 && pulseInfo[selectedPulse] && pulseInfo[selectedPulse]['email']"
                        style="font-weight: 400;font-size: 12px;opacity: 0.6;text-align: right;">
                         {{ selectedPulse != -1 ? getHumanDate(pulseInfo[selectedPulse]['created_date']) + ' ago' : '' }} by 
                        {{ pulseInfo[selectedPulse]['email'] }} 
                    </span>
                </div>
                <div @click="openAudit"
                    style="font-size: 12px;font-weight: 450;color: blue;cursor: pointer;text-align: right;margin-top: 10px;"
                    class="">
                    View History
                </div>
            </span>
            <div style="position: absolute;right: 60px;top: 10px;">
                <button
                    style="background: #4e5fff; cursor: pointer; padding: 0px 8px; border: none; color: white; border-radius: 6px;height: 20px;"
                    @click="handleSetPulse();checkHandle()">
                    <span style="font-weight: 500; font-size: 12px;">Set Pulse</span>
                </button>
            </div>
        </span>
        <span v-if="viewPulse == false">
            <div v-if="colors.length == 0"
                style="display: flex;align-items: center;justify-content: space-evenly; margin-top: 10px;">
                <span style="display: flex;">
                    <input type="radio" v-model="health" name="radAnswer" value="red" placeholder="6m"
                        style="margin-right: 12px;" />
                    <div style="color: white;background-color: red;padding: 0px 10px;">RED</div>
                </span>
                <span style="display: flex;">
                    <input type="radio" v-model="health" name="radAnswer" value="orange" placeholder="6m"
                        style="margin-right: 12px;" />
                    <div style="color: white;background-color: orange;padding: 0px 10px;">ORANGE</div>
                </span>
                <span style="display: flex;">
                    <input type="radio" v-model="health" name="radAnswer" value="green" placeholder="6m"
                        style="margin-right: 12px;" />
                    <div style="color: white;background-color: green;padding: 0px 10px;">GREEN</div>
                </span>
                <span style="display: flex;">
                    <input type="radio" v-model="health" name="radAnswer" value="purple" placeholder="6m"
                        style="margin-right: 12px;" />
                    <div style="color: white;background-color: purple;padding: 0px 10px;">PURPLE</div>
                </span>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-evenly; margin-top: 10px;" v-else>
                <span v-for="item of colors" style="display: flex;">
                    <input type="radio" v-model="health" name="radAnswer" :value=item.value placeholder="6m"
                        style="margin-right: 12px;" />
                    <div :style="{ background: item.color }" style="color: white;padding: 0px 10px;">{{ item.value }}</div>
                </span>
            </div>
            <Multiselect v-if="colors.length==0" style="margin-top: 20px !important;" :options="health == 'green' ? reasonListGood : (health == 'purple') ? reasonListPurple : reasonList"
                v-model="selectedReason" @select="handleReasons()" :multiple="true" :close-on-select="false"
                :clear-on-select="false" :preserve-search="true" />
                <Multiselect v-else style="margin-top: 20px !important;" :options="reason[health]"
                v-model="selectedReason" @select="handleReasons()" :multiple="true" :close-on-select="false"
                :clear-on-select="false" :preserve-search="true" />
            <textarea @input="checkLength" id="myTextarea" style="margin-top: 20px;width: 100%;border: 1px solid #e8e8e8;" placeholder="Enter your comment"
                rows="4" v-model="descselected" />
                <div class="counter">{{ remainingChars }} characters remaining</div>
                <div style="display: flex;align-items: center;justify-content: space-between;width: 90%;">
                    <div style="display: flex;align-items: center;justify-content: space-between;width: 70%;">
                <h5 style="margin-bottom: 0px !important;">Follow up </h5>
                <input type="datetime-local" v-model="followUpDate" placeholder="Enter followup reminder date" style="border-bottom:1px solid grey !important;width: 70%;border: none;"/>
            </div>
            <div v-if="(email && followUpDate&& !isAuthorized) " v-tooltip="'Create Task in Google-Calendar ' +( isAuthorized?' - Authorized':' - UnAuthorized')" @click="handleGooglePressed">
                <!-- {{!isPressed?"☑️":"✅"  }} -->
                <img  src="../assets/img/images.png" :style="{ filter: !isAuthorized ? 'grayscale(100%)' : 'none' }" style="height: 16px;cursor: pointer;" />
            </div>
            <div style="display: flex;align-items: center;margin-top: 0px;" v-if="followUpDate && isAuthorized">
                    <input style="margin-right: 3px;" v-model="isPressed" type="checkbox" />
                    <div style="font-size: 10px;">
                        Add to calendar
                    </div>
            </div>
          
            </div>
           
            <span style="display: flex; align-items: center;justify-content: flex-end;margin-bottom: 20px !important;margin-top: 20px !important;">
                <button @click="viewPulse = true" style="padding: 3px 27px;
    margin-right: 20px;
    margin-top: 0px !important;
    width: 25%;
    font-size: 16px; background-color: white; color: black; border: 1px solid;">Go Back</button>
                <button :disabled="!(health && reasons && selectedReason.length > 0 && descselected)" @click="handleSave"
                    style="margin-top: 0px !important;
    padding: 3px 27px;
    width: 25%;
    font-size: 16px;">Save</button>
            </span>
        </span>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
// import Server from "../server/api"
// import feeds from ".././feeds/instance"
import Server from '../components/API/Server';
import AppEQAnalytics from "../analytics/appeqAnalytics"
import { toast } from "vue3-toastify";
export default {
    components: {
        Multiselect
    },
    props: {
        account_domain: '',
        pulseType:"",
        account_id: '',
        selectedPulseOpen: 0
    },
    data() {
        return {
            selectedPulse: 0,
            pulseInfo: [],
            isPressed:false,
            colors: [],
            accountName: '',
            followUpDate:"",
            descselected: '',
            health: 'purple',
            loading: false,
            selectedReason: '',
            isAuthorized:false,
            reason:{},
            email:"",
            reasons: '',
            reasonList: ["Low Product Usage", 'Relationship and Engagement Risk', 'Support Risk', 'Other Risk'],
            reasonListGood: ["Good Product Usage", 'Good Relationship and Engagement', 'Good Support Feedback', 'Other Reason'],
            reasonListPurple: ["New testimonial", "New referral", "New Case Study", "Logo Approval", "New customer advocacy / community event","Other Reasons"],
            viewPulse: true,
        }
    },
    async mounted() {
        try {
            this.email=localStorage.getItem("__email__id")
            console.log(this.account_domain)
            if (this.account_domain) {
                let companyName = this.account_domain;
                const checktoken=await Server.checkRefreshToken()
                console.log(checktoken)
                if(checktoken.code==200){
                    this.isAuthorized=true
                }
                try {
                    if (companyName.toString().split(".").length > 1) {
                        companyName = companyName.toString().split(".")[0];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                this.accountName = companyName;
                this.accountName = this.accountName.charAt(0).toUpperCase() + this.accountName.slice(1);
            }
            this.loading = true;
            let allowedApps= (await Server.getAllowedApps2(localStorage.getItem("__email__id"))).data.data
            console.log(allowedApps)
            let adminConfigs = allowedApps.appeqAdminConfigs
            if (adminConfigs) {
                adminConfigs = JSON.parse(adminConfigs);
                if (adminConfigs.business_object_name && adminConfigs.type && adminConfigs.type == 'pulse') {
                    this.colors = adminConfigs.colors;
                    this.reason=adminConfigs.reason;
                    // let data = await Server.fetchAllDataForOpen(adminConfigs.business_object_name, 0, this.account_domain, this.account_id);
                    console.log(data);
                    if (data && data.data && data.data.data && data.data.data.data) {
                        this.pulseInfo = data.data.data.data;

                    }
                } else {
                    const data = await Server.readPulseData(this.account_domain, this.account_id)
                    console.log(data);

                    this.pulseInfo = data.data.filter(d => d.strike === undefined || d.strike === true);
                }
            } else if(allowedApps.pulseConfig && this.pulseType){
                console.log("Inside pulse config")
                let config=(allowedApps.pulseConfig)
                console.log(config)
                console.log(this.pulseType)
                this.colors = config[this.pulseType].colors;
                this.reason=config[this.pulseType].reason;
                const data = await Server.readPulseData(this.account_domain, this.account_id)
                console.log(data);
                this.pulseInfo = data.data.filter(d => d.strike === undefined || d.strike === true);
            }else if(allowedApps && allowedApps.pulseUsers){
                let config=(allowedApps.pulseConfig)

                allowedApps.pulseUsers.map(async (d)=>{
                    if(d.pulseType && d.user==localStorage.getItem("__email__id")){
                        this.colors = config[d.pulseType].colors;
                        this.reason=config[d.pulseType].reason;
                        const data = await Server.readPulseData(this.account_domain, this.account_id)
                        console.log(data);
                        this.pulseInfo = data.data.filter(d => d.strike === undefined || d.strike === true);
                    }
                })
            }
            
            else {
                const data = await Server.readPulseData(this.account_domain, this.account_id)
                console.log(data);

                this.pulseInfo = data.data.filter(d => d.strike === undefined || d.strike === true);
            }
            if (this.pulseInfo.length > 0) {
                if (this.pulseInfo.length <= 3) {
                    this.selectedPulse = this.selectedPulseOpen;
                } else {
                    this.selectedPulse = this.pulseInfo.length - this.selectedPulseOpen - 1;
                }
            }
            this.pulseInfo=this.pulseInfo.slice(0,15)
            this.selectedPulse=this.pulseInfo.length-1
            this.loading = false;
      
        } catch (error) {
            console.error(error);
            this.loading = false;
        }
    },
    computed: {
        remainingChars() {
            return 450 - this.descselected.length;
        }
    },

    methods: {
        async handleGooglePressed(){
                const checktoken=await Server.checkRefreshToken()
                console.log(checktoken)
                if(checktoken.code==200){
                    this.isAuthorized=true
                    // this.isPressed=!this.isPressed
                    return
                }else{
                this.$toasted.error(checktoken.msg)
                var client = window.google.accounts.oauth2.initTokenClient({
                    scope: 'https://www.googleapis.com/auth/calendar',
                client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                access_type: 'offline', // Request a refresh token
                prompt: 'consent', // Prompt the user to ensure a refresh token is granted
                callback: async (tokenResponse) => {
                    console.log(tokenResponse)
                 console.log('Access Token:', tokenResponse.access_token);
                const checktoken=await Server.saveAuth(tokenResponse)
                    if(checktoken.code==200){
                        this.$toasted.success("Successfully authorized")
                        this.isAuthorized=true

                    }else{
                        this.$toasted.error(checktoken.msg)
                    }
    // Use the access token to create a task
            },
            
            });

            // Trigger the client to request the token when needed
            client.requestAccessToken();
            }
        },
        checkLength() {
            if (this.descselected.length > 450) {
                this.descselected = this.descselected.substring(0, 450);
            }
        }
,
        checkHandle(){
            
    const textarea = document.getElementById('myTextarea');
    const charCount = document.getElementById('charCount');
    const maxChars = 450;

    textarea.addEventListener('input', () => {
        const remainingChars = maxChars - textarea.value.length;
        charCount.textContent = `${remainingChars} characters remaining`;

        if (remainingChars < 0) {
            textarea.value = textarea.value.substring(0, maxChars);
            charCount.textContent = `0 characters remaining`;
        }
    });
        },
        openAudit() {
            window.parent.postMessage({ action: "__appeq_open_account_audits", data:{account_domain: this.account_domain, id: this.account_id, type: "pulse" }}, "*");

        },
        closePopup() {
            this.$emit("close")

        },
        async handleSetPulse() {
            let adminConfigs = localStorage.getItem('adminConfigs');
            if (adminConfigs) {
                adminConfigs = JSON.parse(adminConfigs);
                if (adminConfigs) {
                    if (adminConfigs.business_object_name && adminConfigs.type && adminConfigs.type == 'pulse' && adminConfigs.setPulse) {
                        if (adminConfigs.setPulse.type && adminConfigs.setPulse.type == "redirect" && adminConfigs.setPulse.redirectTo && adminConfigs.setPulse.key) {
                            // const creds = await Server.getBusinessDataObs()
                            console.log(creds)
                            let urlToRedirect = ''
                            creds.data.map(d => {
                                if (d.instance_url && d.business_object_name == adminConfigs.setPulse.redirectTo) {
                                    urlToRedirect = d.instance_url;
                                }
                            })
                            urlToRedirect += '/lightning/r/' + adminConfigs.setPulse.redirectTo + '/' + this.account_id + '/view'
                            window.open(urlToRedirect)
                            return;
                        }
                    } else {
                        this.viewPulse = false;
                    }
                }
            } else {
                this.viewPulse = false;
            }
        },
        // "type":"redirect","redirectTo":"Contract_Product__c","key":"account_id"
        handleReasons() {
            this.reasons = '';
            for (let i = 0; i < this.selectedReason.length - 1; i++) {
                this.reasons += this.selectedReason[i];
                this.reasons += ' and ';
            }
            this.reasons += this.selectedReason[this.selectedReason.length - 1];
        },
        async handleSave() {
            if (this.reasons == '' || this.selectedReason.length == 0) {
                this.$toasted.error("Select the reason");
                return;
            }
            const data = (await Server.savePulse({ account_domain: this.account_domain, account_id: this.account_id, business_object_name: "pulse", health: this.health, reason: this.reasons, description: this.descselected,followUpDate:this.followUpDate }))

            if (data.code == 200) {
                this.$toasted.success("Pulse updated successfully")
                if(this.isPressed){
                   const calendar= await Server.saveTaskToCalendat("Follow up : "+ this.accountName +" - "+this.reasons+" via AppEQ.ai",this.descselected,this.followUpDate)
                   console.log(calendar)
                }
                // window.location.reload()
                this.viewPulse = true;
            }
            this.$emit("close", { action: "Saved" })
            AppEQAnalytics.actionCall("AccountPulseCreated", "Feed", { "health": this.health, "reason": this.reasons, "description": this.descselected })


        },
        getHumanDate: function (date) {
            var date = new Date((date))
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31534500);

            if (interval > 1) {
                return interval + " years";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " months";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " days";
            }
            interval = Math.floor(seconds / 3450);
            if (interval >= 1) {
                return interval + " hours";
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return interval + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },

    }
}
</script>
<style lang="css" scoped>button {
    background: #4e5fff;
    color: #fff;
    border: 0;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 43px;
    cursor: pointer;
    border-radius: 6px;
    /* width: 100%; */
    margin-top: 15px;
}

.counter{
    font-size: 10px;
    color: grey;
    text-align: right;
}
button:disabled {
    background-color: grey !important;
    color: white !important;
}</style>
<template>
    <div class="tasks">
     
       
        <div class="tasks__header" v-if="openedFromNudge">
           <h2> Below are the Tasks({{ tasks.length }}) created by Nudge & Playbook - <span style="color:deeppink">{{ nudgeName }}</span></h2>
        </div>
        <div style="margin-left: 0px;margin-top: 10px;margin-bottom: 10px;" class="row">
            <div class="searchBox">
                <img style="height: 12px;opacity: 0.5;" src="../icons/searchIcon.svg" />
                <input @input="searchTask" v-model="searchInput" class="searchBoxInp" type="text" placeholder="Find Task"
                    style="outline: none; font-size: 12px; border: none; border-radius: 7px; padding: 6px;width: 350px;height: 30px;">

            </div>
           
            <div style="display: flex;align-items: center;justify-content: flex-end;flex: 1;" class="">
                <button class="addTasks" @click="openAddTasks">+ Add Task</button>
                <!-- <div role="tooltip" aria-label="Open Task Analytics" data-microtip-position="left" class="">
                    <img style="height: 18px;cursor: pointer;" @click="openTasksAnalytics" src="../icons/analytics.png" />

                </div> -->
            </div>
        </div>
        <div>
            <div v-if="!loading" class="tasks__body">
                <table>
                    <tr>
                        <th v-for="(col, idx) of columns">
                            <div style="display: flex;align-items: center;">
                                {{ col }}
                                <div v-if="sortCols[idx]"
                                    style="display: flex;flex-direction: column;margin-left: 8px;cursor: pointer;">
                                    <img :class="{ sort: sortedIdx == idx }" @click="handleSort(mappingFields[idx], idx)"
                                        style="height: 8px;width: 8px;" src="../icons/uparrow.png">
                                    <img :class="{ sort: sortedIdx == idx }" @click="handleSort(mappingFields[idx], idx)"
                                        style="height: 8px;width: 8px;" src="../icons/arrow-down-sign-to-navigate.png">

                                </div>
                            </div>
                        </th>

                    </tr>
                    <tr :style="{ color: getHealthRed(task) ? 'red' : '', opacity: task['status'] == 'completed' ? '0.6' : '1', fontWeight: task['status'] != 'completed' && checkDate(task['duedate'], task) == 'Today' ? 'bold' : '' }"
                        v-for="(task, idx) of tasks">
                        <td :style="{ width: widthCols[idx2], 'overflow': 'visible', 'z-index': '9','position': 'relative' }"
                            v-for="(map, idx2) of mappingFields">
                            <div @click="handleOpenTicket(task['account_domain'], task['account_id'])" style="display: flex;align-items: center;" v-if="map == 'account_domain'">
                                <!-- <input style="margin-bottom: 0px;margin-right: 4px;" type="checkbox" /> -->
                                <img  class="account_icon" style="cursor: pointer;" @error="changeImg($event)"
                                    :src="getIcon(task['account_domain'])" />
                                <span 
                                    style="font-weight: 500;color: blue;text-transform: capitalize;cursor: pointer;">
                                    {{ accountName(task[map]) }}
                                </span>
                            </div>
                            <div v-else-if="map == 'desc'">
                                <div style="font-weight: 600;">{{ task['task_name'] }}</div>
                                <span v-if="openEditDesc && idx == currentIndex"
                                    style="display: flex; align-items: center; justify-content: flex-start;">
                                    <!-- <input type="text" v-model="task[map]" style="outline: none; border: none; border-bottom: 1px solid #e8e8e8;"> -->
                                    <textarea id="text" v-model="task[map]" style="outline: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(232, 232, 232);
    border-left: none;
    background: rgb(0 0 255 / 5%);
    border-image: initial;
    padding: 8px;
" name="text" rows="4" cols="50">{{ task[map] }}</textarea>
                                    <img src="../icons/check-mark.png"
                                        style="height: 12px; margin-left: 8px; cursor: pointer;"
                                        @click="handleSaveEditedDesc(task)" alt="">
                                </span>
                                <span v-else >
                                    <span class="task_analytics_tooltip">
                                        <span style="color: grey; font-size: 11px;" role="tooltip"
                                        content-class="custom-tooltip"

                                        v-tooltip.top-right="task[map]" data-microtip-position="right">
                                        {{ task[map] ? (task[map].toString().length > 140 ? task[map].substr(0, 140) + "..." :
                                            task[map]) : "NA"
                                        }}
                                    </span>
                                    </span>
                                    <span role="tooltip" aria-label="Edit" data-microtip-position="left">
                                        <img @click="openEditDesc = true; currentIndex = idx;" src="../icons/penciledit.svg"
                                            style="height: 10px; margin-left: 8px; filter: invert(55%) sepia(0%) saturate(66%) hue-rotate(218deg) brightness(91%) contrast(91%); cursor: pointer;"
                                            alt="">
                                    </span>
                                </span>
                            </div>
                            <div v-else-if="map == 'duedate'">
                                <span v-if="openEditDueDate && idx == currentIndex"
                                    style="display: flex; align-items: center; justify-content: flex-start;">
                                    <!-- <input type="text" v-model="task[map]" style="outline: none; border: none; border-bottom: 1px solid #e8e8e8;"> -->
                                    <input type="datetime-local" name="" v-model="task['duedate']" id=""
                                        style="outline: none; border: none; border-bottom: 1px solid #e8e8e8;">
                                    <img src="../icons/check-mark.png"
                                        style="height: 12px; margin-left: 8px; cursor: pointer;"
                                        @click="handleSaveEditedDueDate(task)" alt="">
                                </span>
                                <span v-else>
                                    {{ checkDate(task['duedate'], task) }}
                                    <span role="tooltip" aria-label="Edit" data-microtip-position="left">
                                        <img @click="openEditDueDate = true; currentIndex = idx;" src="../icons/penciledit.svg"
                                            style="height: 10px; margin-left: 8px; filter: invert(55%) sepia(0%) saturate(66%) hue-rotate(218deg) brightness(91%) contrast(91%); cursor: pointer;"
                                            alt="">
                                    </span>
                                </span>
                            </div>
                            <div v-else-if="map == 'via'">
                                <div role="tooltip" v-tooltip="'Created - ' +task['via']['createdBy']" data-microtip-position="bottom" v-if="task['via']">
                                    {{ task['via']['createdBy'].substr(0,8)+"...." }}  <div style="font-size: 12px;" class="">
                                        <!-- via {{ task['via']['application'] }}  -->

                                    </div>                                </div>
                            </div>
                            <div v-else-if="map == 'status'">
                                <div :class="task['status']">
                                    {{ task['status'] }}
                                </div>
                            </div>
                            <div role="tooltip" v-tooltip="task.assignee" data-microtip-position="bottom" class="" v-else-if="map=='assignee' && task.assignee">
                                {{ task.assignee.substr(0, 8) + '...' }} 
                            </div>
                            <div v-else-if="map == 'actions'">
                                <span role="tooltip" aria-label="Reopen" data-microtip-position="right"
                                    @click="handleReopen(task)" style="cursor: pointer;"
                                    v-if="task['status'] == 'completed'">
                                    <img style="height: 16px;filter:invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);cursor: pointer;"
                                        src="../icons/open.png" />
                                </span>
                               
                                <span v-if="task['status'] != 'completed'" role="tooltip" v-tooltip="'Edit Task'"
                                    data-microtip-position="right"><img @click="editClicked(task)"
                                        style="height: 12px;filter:invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);cursor: pointer;"
                                        src="../icons/edit.png" /></span>
                                <span role="tooltip" v-tooltip="'Mark as Completed'" data-microtip-position="left"><img
                                        v-if="task['status'] != 'completed'" @click="handleComplete(task)"
                                        style="height: 12px;margin-left:12px;filter:invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);cursor: pointer;"
                                        src="../icons/check-mark.png" /></span>
                                <span role="tooltip" v-tooltip="'Redirect'" data-microtip-position="right"><img
                                        @click="openTaskLink(task)"
                                        v-if="task && task.appeq_supported_application == 'salesforce'"
                                        style="height: 12px;margin-left:12px;filter:invert(0%) sepia(0%) saturate(7489%) hue-rotate(138deg) brightness(20%) contrast(98%);cursor: pointer;"
                                        src="../icons/redirect.png" /></span>
                                        <span @click="handleAddToGoogleCalendar(task)" v-tooltip="'Add this task to google calendar'">
                                    <img  src="../assets/img/images.png"  style="height: 14px;cursor: pointer;margin-left: 12px;" />
                                </span>
                            </div>
                            <div v-else-if="map == 'desc'">
                                <span v-if="openEditDesc && idx == currentIndex"
                                    style="display: flex; align-items: center; justify-content: flex-start;">
                                    <input type="text" v-model="task[map]" style="outline: none; border: none; border-bottom: 1px solid #e8e8e8;">
                                    <textarea id="text" v-model="task[map]" style="outline: none;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid rgb(232, 232, 232);
    border-left: none;
    background: rgb(0 0 255 / 5%);
    border-image: initial;
    padding: 8px;
" name="text" rows="4" cols="30">{{ task[map] }}</textarea>
                                    <img src="../icons/check-mark.png"
                                        style="height: 12px; margin-left: 8px; cursor: pointer;"
                                        @click="handleSaveEditedDesc(task)" alt="">
                                </span>
                                <span v-else>
                                    {{ task[map] ? (task[map].toString().length > 50 ? task[map].substr(0, 50) + "..." :
                                        task[map]) : "NA"
                                    }}
                                    <span role="tooltip" aria-label="Edit" data-microtip-position="right">
                                        <img @click="openEditDesc = true; currentIndex = idx;" src="../icons/penciledit.svg"
                                            style="height: 10px; margin-left: 8px; filter: invert(55%) sepia(0%) saturate(66%) hue-rotate(218deg) brightness(91%) contrast(91%); cursor: pointer;"
                                            alt="">
                                    </span>
                                </span>
                            </div>
                            <div style="display: flex;align-items: center;" class="" v-else-if="map=='task_type'">
                                {{ task[map] }}
                                <!-- <div role="tooltip" aria-label="Send AI Assisted Email" data-microtip-position="bottom" class="">
                                    <img @click="handleOpenEmailpopup(task)" style="height: 16px;cursor: pointer;margin-left: 10px;" src="../icons/sendIcon.svg" v-if="task['task_type'] && task['task_type'].toLowerCase()=='email' && task['email_template']" />

                                </div> -->
                            </div>
                            <div v-else>
                                {{ task[map] ? (task[map].toString().length > 140 ? task[map].substr(0, 140) + "..." :
                                    task[map]) : "NA"
                                }}

                            </div>
                        </td>
                    </tr>
                </table>
            </div>
          
        </div>
        <div style="display: flex;align-items: center;justify-content: center;" v-if="loading">
            <img style="height: 120px;" src="../icons/loadingripple.svg" />
        </div>
        <div style="position: fixed; right: 0%; bottom: 0%; height: 70%; width: 35%; z-index: 99999999;" v-if="openEmailPopup" class="">
            <ComposeBox @close="openEmailPopup=false;" :emailTemplate="emailTemplateAvailable" :accountDomain="emailPopupdomain" :taskDesc="taskDesc" />

        </div>
        <div style="position: fixed; left: 0%; top: 0%; background: rgba(0,0,0,.3); height: 100%; width: 100%; z-index: 99999999;"
            v-if="addTaskModal">
            <div class="modalTask" v-if="addTaskModal">

                <AddTaskModal @save="addTaskModal = false; initData();" :taskSelected="taskSelected"
                    @close="addTaskModal = false" />

            </div>
        </div>
       <Modal class="modal-new-summary" :show="showNewTicket" v-if="showNewTicket">
        <div style="display: flex;align-items: flex-end;justify-content: flex-end;position: absolute;top:6px;right: 6px;cursor: pointer;" class="">
            <img src="../icons/crossButton.svg" style="font-size: 14px;cursor: pointer;" @click="showNewTicket=false"/>

        </div>
        <SummaryNew :domain="accountDomain" :id="accountId" @close="showNewTicket=false"/>
       </Modal>

    </div>
</template>
<script>
// import Server from "../server/api"
import moment from "moment"
import Server from "./API/Server"
import RandomTask from "../pages/RandomTask.json"
// import ComposeBox from "./ComposeBox.vue"
// import feeds from "../feeds/instance"
// import { toast } from 'vue3-toastify';
import AddTaskModal from "./AddTaskModal.vue"
// import NewTickets from "../feeds/NewTickets.vue";
// import AppEQAnalytics from "../analytics/appeqAnalytics"
import Multiselect from "vue-multiselect";
import { JWT } from "node-jsonwebtoken";
// import IoTThingsGraph from "aws-sdk/clients/iotthingsgraph";
import axios from "axios";
import { formatDate } from "tough-cookie";
import Modal from "./Modal.vue";
import SummaryView from "./SummaryView.vue";
import SummaryNew from "../pages/SummaryNew.vue";

export default {
    props:[
        "openTaskForNudge",
        "filterCond",
        "filterTasks"
],
computed:{
    formattedDueDate: {
      get() {
        const date = new Date(this.dueDate);
        return date.toISOString().slice(0, 10); // Convert to YYYY-MM-DD format
      },
      // Reverse the process when the date is changed by the user
      set(newDate) {
        const date = new Date(newDate);
        this.dueDate = date.toUTCString(); // Convert back to the original format if needed
      }
    }
  
},
    data: function () {
        return {
            openEmailPopup:false,
            emailPopupdomain:"",
            showSummary:false,
            openedFromNudge:false,
            nudgeName:"",
            ticketTasks:[],
            columns: ["Customer Name", "Type", "Title", "status", "Due date","Assignee", "Creator", "Actions"],
            customerSel: "",
            mappingFields: ["account_domain", "task_type", "desc", "status", "duedate","assignee", "via", "actions"],
            sortCols: [true, false, true, false, false, false, false, false],
            oldTasks: [],
            customers: [],
            filterType: "All",
            widthCols: ["6%", "6%", "19%", "6%", "6%", "6%", "6%","6%" ,"6%", "20%"],
            loading: false,
            showCompleted: false,
            tasks: [],
            sortedIdx: -1,
            searchInput: "",
            showNewTicket: false,
            emailTemplateAvailable: '',
            accountDomain: '',
            accountId: '',
            health: '',
            reason: '',
            tasks: '',
            info: '',
            timeofday: '',
            openCount: 0,
            dueToday: 0,
            
            taskDesc: {},
            taskSelected: null,
            openEditDesc: false,
            openEditDueDate: false,
            currentIndex: 0,
            user: '',
            addTaskModal: false,
        }
    },
    mounted() {
        this.user = localStorage.getItem("__email__id")
        this.initData()
        var today = new Date()
        var curHr = today.getHours()
        // const cust = JSON.parse(localStorage.getItem("MY_CUSTOMERS"))
        // this.customers = cust['data'].map(d => d.domain)
        if (curHr < 12) {
            this.timeofday = 'morning'
        } else if (curHr < 18) {
            this.timeofday = 'afternoon'
        } else {
            this.timeofday = 'evening'
        }
    },
    components: {
        Multiselect,
        AddTaskModal,
        Modal,
        SummaryView,
        SummaryNew
    },
    watch: {
        customerSel: async function (val) {
            console.log(val);
            if (val == null) {
                this.tasks = this.oldTasks;
            }
        }
    },
    methods: {
        async handleAddToGoogleCalendar(task){

            const checktoken=await Server.checkRefreshToken()
                console.log(checktoken)
                if(checktoken.code==200){
                    this.isAuthorized=true
                    console.log(task['duedate'])
                    // this.isPressed=!this.isPressed
                    const googleData=await Server.saveTaskToCalendat(task['account_domain']+" : "+ task['task_name'],task['desc'],moment(task['duedate']).format("YYYY-MM-DDTHH:mm"))
                        console.log(googleData)
                        if(googleData && googleData.code==200){
                            this.$toasted.success("Task created in google calendar succesfully")
                        }
                    return
                }else{
                // this.$toasted.error(checktoken.msg)
                var client = window.google.accounts.oauth2.initTokenClient({
                    scope: 'https://www.googleapis.com/auth/calendar',
                client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                access_type: 'offline', // Request a refresh token
                prompt: 'consent', // Prompt the user to ensure a refresh token is granted
                callback: async (tokenResponse) => {
                const checktoken=await Server.saveAuth(tokenResponse)
                    if(checktoken.code==200){
                        this.$toasted.success("Successfully authorized")
                        this.isAuthorized=true
                        const googleData=await Server.saveTaskToCalendat(task['account_domain']+" : "+ task['task_name'],task['desc'],moment(task['duedate']).format("YYYY-MM-DDTHH:mm"))
                        console.log(googleData)
                        if(googleData && googleData.code==200){
                            this.$toasted.success("Task created in google calendar succesfully")
                        }

                    }else{
                        this.$toasted.error(checktoken.msg)
                    }
    // Use the access token to create a task
            },
            
            });

            // Trigger the client to request the token when needed
            client.requestAccessToken();
            }
            // const data = await Server.saveTasks(task)

        },
        formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().slice(0, 10); // Convert to YYYY-MM-DD format
    },
        handleOpenEmailpopup(task){
            console.log(task)
            this.emailPopupdomain=task['account_domain']
            this.openEmailPopup=!this.openEmailPopup
            this.taskDesc = task;
            this.emailTemplateAvailable = task['email_template'];
        },
        handleFilterCustomers(ev) {
            console.log(ev)
            this.tasks = this.oldTasks.filter(d => d.account_domain.toLowerCase().includes(ev.toLowerCase()))
        },
        async handleOpenTicket(domain, id){
            this.accountDomain = domain;
            this.accountId = id;
            // let data = await Server.getAccountIdDomain(id);
            // if(data.data && data.data.data && data.data.data.health && data.data.data.info){
            //     this.health = data.data.data.health.data[0].health;
            //     this.reason = data.data.data.health.data[0].reason;
            //     this.info = data.data.data.info[0].data;
            //     this.ticketTasks = data.data.data.info[0].tasks;
            // }
            this.showNewTicket = true;
        },
        handleFilterType(){
            console.log(this.filterType);
            if(this.showCompleted){
                this.initData(true, this.filterType);
            }else{
                this.initData(false, this.filterType);
            }
        },
        async openTasksAnalytics() {
            let usersData = (await Server.getUserInfo(localStorage.getItem('__email__id'))).data
            var orgId = usersData.data.org_id

            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: 134 },
                params: {
                    appeq_org_id: orgId,
                    email: localStorage.getItem("__email__id")
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";

            feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);
            return;
        },
        searchTask() {
            this.tasks = this.oldTasks.filter(d => d.task_name.toLowerCase().includes(this.searchInput.toLowerCase()) || (d.desc && d.desc.toLowerCase().includes(this.searchInput.toLowerCase())))
        },
        async openTaskLink(task) {
            const creds = await Server.googleSheetCredentials(localStorage.getItem("__email__id"))
            let urlToRedirect = ''
            creds.data.map(d => {
            console.log(d)
                if (d.instance_url) {
                    urlToRedirect = d.instance_url
                }
            })
            if (task.Id) {
                urlToRedirect += '/lightning/r/Task/' + task.Id + '/view'
                window.open(urlToRedirect)
            }

        },
        async handleSaveEditedDesc(task) {
            const data = await Server.saveTasks(task)
            if (data.code == 200) {
                this.$toasted.success("Tasks Updated successfully")
                this.$emit("save")
            } else {
                this.$toasted.error("Error occured while saving tasks.")
            }
            this.openEditDesc = false;
        },
        async handleSaveEditedDueDate(task) {
            const data = await Server.saveTasks(task)
            if (data.code == 200) {
                this.$toasted.success("Tasks Updated successfully")
                this.$emit("save")
            } else {
                this.$toasted.error("Error occured while saving tasks.")
            }
            this.openEditDueDate = false;
        },
        async handleReopen(task) {
            try {
                const d = await Server.openTasks(task['task_type'], task['task_name'], task['assignee'], task['account_domain'], task['account_id'])
                if (d.code == 200) {
                    this.$toasted.success("Successfully re-opened the task")
                    await this.initData()
                } else {
                    this.$toasted.error("Error occured while re-opening task")
                }
            } catch (error) {
                console.log(error)
            }
        },
        changeImg(e) {
            e.target.src = 'https://placehold.co/400'
        },
        checkDate(dateInput, task) {
            if (task["status"] == "completed") {
                const days = moment(task["updated_date"]).diff(task["duedate"], 'days')
                return days.toString().replace("-", "") + (days < 0 ? " days early" : " days late")
            }
            const inputDate = new Date(dateInput);
            const currentDate = new Date();

            const inputYear = inputDate.getFullYear();
            const inputMonth = inputDate.getMonth();
            const inputDay = inputDate.getDate();

            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();

            const comparison = inputDate.toDateString() === currentDate.toDateString();

            if (comparison) {
                return "Today";
            } else if (
                inputYear === currentYear &&
                inputMonth === currentMonth &&
                inputDay === currentDay - 1
            ) {
                return "Yesterday";
            } else if (
                inputYear === currentYear &&
                inputMonth === currentMonth &&
                inputDay === currentDay + 1
            ) {
                return "Tomorrow";
            } else if (inputDay < currentDay && inputMonth <= currentMonth) {
                return moment(dateInput).diff(new Date(), 'days') + ' days '
            }
            else {
                return moment(dateInput).format("DD MMM");
            }
        },
        getHumanDate(date) {
            return moment(date).format("DD MMM")

        },
        handleSort(col, idx) {
            this.sortedIdx = this.sortedIdx == idx ? -1 : idx;
            this.tasks.sort((a, b) => {
                if (a[col] < b[col]) {
                    return -1;
                }
                if (a[col] > b[col]) {
                    return 1;
                }
                return 0;
            })
        },
        async handleComplete(task) {
            try {
                const id = task['account_id']
                const taskId = task['Id']
                task['status']='completed'
                task['priority']='Normal'
                const data=await Server.saveNewTasks(task)
                console.log(data,"test1234")
                if(data && data.code && data.code==200){
                    console.log("IN TOASTER")
                    toast.success("Task Completed Successfully")
                    this.initData()
                }else{
                    if(data.msg){
                        toast.error(data.msg)
                    }else{
                        toast.error("Something Went Wrong")
                    }
                }
                // const creds = await Server.getBusinessDataObs()
                // if (creds.data.findIndex(d => d.business_object_name == 'accounts') > -1) {
                //     const idx = creds.data.findIndex(d => d.business_object_name == 'accounts')
                //     this.syncApplication = task['appeq_supported_application']
                //     if (this.syncApplication == 'salesforce') {
                //         await this.handleAddToSalesforce(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], creds.data[idx]['instance_url'], id, taskId, task)
                //     } else if (this.syncApplication == "hubspot") {
                //         let idTask = task['id']

                //         await this.handleRefreshTokenAndCompleteHubSpotTask(creds.data[idx]['refresh_token'], idTask)
                //         const d = await Server.completeTasks(task)
                //         if (d.code == 200) {
                //             toast.success("Successfully completed the task")
                //             await this.initData()
                //         } else {
                //             toast.error("Error occured while completing task")
                //         }
                //     } else {
                //         task['status']='completed';
                //         const d = await Server.completeTasks(task)
                //         if (d.code == 200) {
                //             toast.success("Successfully completed the task")
                //             await this.initData()
                //         } else {
                //             toast.error("Error occured while completing task")
                //         }
                //     }
                // }

            } catch (error) {
                console.log(error)
            }
        },
        async handleAddToSalesforce(accessToken, refreshToken, instanceUrl, accountId, taskId, task) {
            const clientId = '3MVG9wt4IL4O5wvLgb9I5m8DvdLV3B_WpFrNAM6bHFe9w6BqDaJTyoQ2pI4JsP8LqwJ98EyTODeYtVFvLX91S';
            const clientSecret = '148F2E93D23C83B291CDB7197CC31EDED6E11B5FED7D55F04603957A7937045B';
            const redirectUri = chrome.identity.getRedirectURL();
            const vm = this;
            this.syncLoading = true;
            this.syncStatus = 'LOADING';


            const tokenUrl = 'https://login.salesforce.com/services/oauth2/token';
            const data = {
                grant_type: 'refresh_token',
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken
            };

            fetch(tokenUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: new URLSearchParams(data)
            })
                .then(response => response.json())
                .then(tokenData => {
                    const accessToken = tokenData.access_token;
                    console.log('New Access Token:', accessToken);

                    // Now you can use the new access token to interact with Salesforce API

                    const taskEndpoint = instanceUrl + '/services/data/v53.0/sobjects/Task/' + taskId;

                    const taskData = {
                        Status: 'Completed',
                    };

                    fetch(taskEndpoint, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(taskData)
                    })
                        .then(response => response.ok)
                        .then(async responseData => {
                            const d = await Server.completeTasks(task)
                            if (d.code == 200) {
                                this.$toasted.success("Successfully completed the task")
                                await vm.initData()
                            } else {
                                this.$toasted.error("Error occured while completing task")
                            }
                            // Handle success

                            vm.syncLoading = false;
                            // Additional code as needed
                        })
                        .catch(error => {
                            console.error('Error creating task:', error);
                            // Handle error
                            vm.syncLoading = false;
                            vm.syncStatus = 'ERROR'
                            vm.errorMsg = error
                            // Additional code as needed
                        });

                })
                .catch(error => {
                    console.error('Error obtaining new access token:', error);
                    vm.syncStatus = 'ERROR';
                });
        },
        async handleRefreshTokenAndCompleteHubSpotTask(refreshToken, taskId) {
            const clientId = '6951dc5d-cab1-43b6-a76e-34a85d504058';
            const clientSecret = 'ad944f03-0a0f-49ae-a289-847851ed0f2e';
            const hubspotEndpoint = `https://api.hubapi.com/oauth/v1/token`;

            const data = {
                grant_type: 'refresh_token',
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken,
            };

            try {
                const response = await fetch(hubspotEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                    },
                    body: new URLSearchParams(data),
                });

                if (response.ok) {
                    const tokenData = await response.json();
                    const accessToken = tokenData.access_token;

                    // Now you can use the new access token to interact with HubSpot API

                    const taskEndpoint = `https://api.hubapi.com/crm/v3/objects/tasks/${taskId}`;

                    const taskData = {
                        properties: {
                            'hs_task_status': 'COMPLETED', // Replace with the actual property name for task status
                            // Add other properties you want to update as needed
                        },
                    };

                    const updateResponse = await fetch(taskEndpoint, {
                        method: 'PATCH',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(taskData),
                    });

                    if (updateResponse.ok) {
                        // Handle success
                        console.log('Task completed successfully in HubSpot');
                        // Additional code as needed
                    } else {
                        // Handle error
                        console.error('Error completing task in HubSpot:', updateResponse.status, await updateResponse.text());
                        // Additional code as needed
                    }
                } else {
                    // Handle error
                    console.error('Error obtaining new access token from refresh token:', response.status, await response.text());
                }
            } catch (error) {
                console.error('Error completing task in HubSpot:', error);
                // Handle error
            }
        }

        // Example usage:


        ,
        openAddTasks() {
            this.taskSelected = null
            this.addTaskModal = !this.addTaskModal
        },
        editClicked(task) {
            this.taskSelected = task;
            this.addTaskModal = !this.addTaskModal

        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://logo.clearbit.com/' + 'zendesk.com';
                else if (context_link.toString().split("@").length > 1)
                    return 'https://logo.clearbit.com/' + context_link.toString().split("@")[1];

                else if (context_link.split(".").length > 1 ? "" : ".com")
                    return 'https://logo.clearbit.com/' + context_link.toString().split(" ").join("") + ".com";
                else
                    return 'https://logo.clearbit.com/' + context_link;

            } catch (e) {
                console.log(e);
            }
        },
        accountName(domain) {
            if (domain) {
                return domain.split(".")[0]
            }
        },
        getHealthRed(d) {
            return moment(d['duedate']).diff(new Date(), 'days') < 0 && (d['status'] != 'completed')
        },
        async initData(showComp, filterType) {
            try {
                // const creds=await Server.googleSheetCredentials(localStorage.getItem("__email__id"))
                // if(creds && creds.data&& creds.data.length==0){
                //     this.tasks=RandomTask ;
                //     // this.loading=false
                //     return
                //     // const demoExists=await ApiServer.checkDemo()
                //     //  console.log(demoExists);
                //     // Storage.sendActionToTop("__appeq_open_demo_iframe", {email:localStorage.getItem("__email__id")});
                // }
                if (showComp) {
                    this.showCompleted = true
                }
                if(!filterType){
                    filterType = null;
                }
                if(typeof (this.filterCond)=="string"){
                    filterType=this.filterCond
                    this.filterCond=null
                }
                this.loading = true
                const data = await Server.getTasks(this.filterTasks);
                console.log(data)

                if (data ) {
                    console.log(data)
                    this.tasks = data.tasks

                    console.log(this.tasks.filter(d => (d.duedate && (moment(d['duedate']).diff(new Date(), 'days') <= 1) && d["status"] != 'completed')))
                    this.dueToday = this.tasks.filter(d => (d.duedate && (moment(d['duedate']).isBefore(new Date())) && d["status"] != 'completed')).length
                    this.tasks.map(d => {
                        console.log(d['status'])
                        if (d['status'] == 'completed') {
                            return
                        }
                        if (moment(d['duedate']).diff(new Date(), 'days') < 0 && (d['status'] != 'completed')) {
                            // d['health'] = 'red'
                        }

                    })
                    // this.tasks.sort(d=>d.duedate)
                    this.openCount = this.tasks.filter(d => d.status == 'open' || !d.status).length
                }
                this.oldTasks = this.tasks
                console.log(this.openTaskForNudge)
                if(this.openTaskForNudge && this.openTaskForNudge.value && this.openTaskForNudge.value.nudgeName){
                console.log("here")
                console.log(this.openTaskForNudge.value.nudgeName)


                this.tasks=this.tasks.filter(d=>d.createdfrom==this.openTaskForNudge.value.nudgeName)
                this.openedFromNudge=true
                this.nudgeName=this.openTaskForNudge.value.nudgeName
                }
                this.loading = false
            } catch (error) {
                console.log(error)
            }

        }
    }
}
</script>
<style lang="css" scoped>
.addTasks{
    margin-left: 20px;
    /* background: #4e5fff; */
    background: transparent;
    color: blue;
    border: 1px solid blue;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 30px;
    cursor: pointer;
    border-radius: 25px;
    width: 120px;
    margin-top: -10px;
    margin-right: 7%;
}

.title {
    font-weight: 600;
    color: black;
}

.tasks {
    padding: 30px;
    height: 88vh;
    width: 100%;
    overflow: auto
}

.tasks__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

button {
    margin-left: 20px;

    background: #4e5fff;
    color: #fff;
    border: 0;
    padding: 0 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    height: 30px;
    cursor: pointer;
    border-radius: 6px;
    width: 120px;
    margin-top: -10px;
}

.account_icon {
    border-radius: 50%;
    width: 25px;
    margin-right: 9px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th {
    text-transform: capitalize;
    padding: 8px;
    text-align: left;
    max-width: 220px;
    white-space: nowrap;
    /* Prevent line breaks inside table cells */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Truncate text beyond the specified width */
    width: 220px;
    /* Set the maximum width for the table cells */
    border-bottom: 1px solid #ddd;
    /* Add a border-bottom to separate rows */
}

.modalTask {
    position: fixed;
    left: 25%;
    top: 25%;
    background-color: white;
    border: 0.2px solid grey;
    width: 50%;
    border-radius: 10px;
    z-index: 9999999999;
    padding: 5px 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.completed {
    padding: 2px 10px;
    border-radius: 12px;
    width: 60px;
    text-align: center;

}

.open {
    padding: 2px 10px;
    border-radius: 12px;
    width: 60px;
    text-align: center;

}

.sort {
    filter: invert(35%) sepia(57%) saturate(5303%) hue-rotate(228deg) brightness(105%) contrast(102%);
}

td {
    padding: 8px;
    max-width: 220px;
    font-size: 10px;
    text-align: left;

    white-space: pre-wrap;
    /* Prevent line breaks inside table cells */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Truncate text beyond the specified width */
    width: 220px;
    /* Set the maximum width for the table cells */
}

/* [aria-label][role~=tooltip]:after {
    background: rgba(17, 17, 17, .9);
    border-radius: 4px;
    color: #fff;
    content: attr(aria-label);
    font-size: var(--microtip-font-size, 12px);
    font-weight: var(--microtip-font-weight, normal);
    text-transform: var(--microtip-text-transform, none);
    padding: 0.5em 0.5em;
    white-space: nowrap;
    box-sizing: content-box;
    z-index: 99999999999;
    position: absolute;
} */

.row {
    display: flex;
    align-items: center;
}

.searchBox {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid #e8e8e8;
    padding-left: 6px;
    margin-right: 10px;
    border-radius: 8px;
}

.searchBoxInp {
    width: 40vw !important;
    height: 27px !important;
}</style>

<style lang="scss">
.v-tooltip__content{
    max-width: 10px !important; /* Control the width */
  white-space: normal !important; /* Allow text wrapping */
  word-wrap: break-word !important; /* Break long words */
}
.custom-tooltip{
    background-color: red !important;
    max-width: 10px !important; /* Control the width */
  white-space: normal !important; /* Allow text wrapping */
  word-wrap: break-word !important; /* Break long words */
}

</style>
<template>
  <div class="compose-email">
    <header class="compose-header">
      <h3 style="margin-bottom: 0px;font-size: 14px;font-weight: 600;">New Message</h3>
      <img @click="closeCompose" style="font-size: 14px;cursor: pointer;" src="../icons/crossButton.svg" />
      <!-- <button class="close-btn" @click="closeCompose">✕</button> -->
    </header>
    <div class="form-body">

      <!-- To Field -->
      <div class="form-group">
        <!-- <input
        type="text"
        v-model="emailData.to"
        placeholder="To"
        class="email-input"
      /> -->
        <Multiselect :taggable="true" @tag="addTag($event, index)"  @select="handleChange" v-model="emailData['to']" style="width: 30% !important;" placeholder="To" :options="toOptions" />
        <!-- <button class="cc-bcc-toggle" @click="toggleCcBcc">Cc Bcc</button> -->
      </div>

      <!-- Subject Field -->
      <div class="form-group">
        <input type="text" v-model="emailData.subject" placeholder="Subject" class="email-input" />
      </div>

      <!-- Email Body Field -->
      <div class="form-group">
        <textarea style="border: none !important;border-bottom: none !important;height: 45vh;"
          v-model="emailData['body']" rows="20" class="email-body"></textarea>
      </div>
    </div>
    <!-- <Modal class="generate-modal"  :show="openGenerateModal" v-if="openGenerateModal"> -->
      <div v-if="openGenerateModal" class="generate-model">
        <GenerateModal @pdf-downloaded="handlePdfDownloaded($event)" @close="openGenerateModal=false" :dashboardId="emailparams['dashboardId']"/>

      </div>
    <!-- </Modal> -->

    <!-- Footer with Send Button -->
    <footer class="compose-footer">
      <div v-if="pdfBlob!=null" style="display: flex;align-items: center;margin-bottom: 12px;margin-left: 14px !important" class="">
        <img @click="handleView"  src="https://play-lh.googleusercontent.com/hoJp176QsxUS8k5O0i0VeX6V5QX1ywx90YxPckWjyjN7_qXwMBhSMdH6TkPwci9mkw" style="height: 16px;margin-right: 10px;"/>
        <span @click="handleView"  style="color: blue;cursor: pointer;">View File</span>
      </div>
      <div v-else
        style="font-size: 14px;margin-left: 4px;color: blue;cursor: pointer;display: flex;align-items: center;margin-bottom: 12px;margin-left: 14px !important;"
        class="">
        <img  @click="handleGenerateEmail"  src="../assets/View.png" style="height: 18px;cursor: pointer;" />
        <span  @click="handleGenerateEmail"  style="margin-left: 15px;color: blue;">Generate Attachment</span>
      </div>
      <div style="display: flex;align-items: center;" class="">
        <button class="send-btn" @click="sendEmail(emailData['to'])">Send</button>
        <div @click="sendEmail()" v-tooltip="'Send a test mail to yourself'" class="test-btn" style="background: none !important;color: blue;margin-left: 10px;cursor: pointer;border: none !important; font-size: 12px;">Test Email</div>
        
      </div>
    </footer>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect"
import Server from "../components/API/Server";
import Modal from "../components/Modal.vue"
import GenerateModal from "./GenerateModal.vue";
export default {
  components: {
    Multiselect,
    Modal,
    GenerateModal
  },
  props: {
    emailparams: {}
  },
  async mounted() {
    try {
      this.myEmail=localStorage.getItem("__email__id")
      console.log(this.emailparams)
      const contactOptions = await Server.getContacts(
        {
          "$and": [{ account_domain: { "$eq": this.emailparams['domain'] } }]
          , business_object_name: "Contacts",
          email: localStorage.getItem("__email__id")
        }, 1)
        this.contacts=contactOptions.data
      this.toOptions = contactOptions.data.map(d => d.email)
      if(this.emailparams && this.emailparams['assistId']){
         const emailForm=await Server.getpromptsForDashboard()
         if(emailForm && emailForm['data'] && Array.isArray(emailForm['data'])){
            const findById=emailForm['data'].filter(d=>d.promptId==this.emailparams['assistId'])
            this.emailData['subject']=findById[0]['templateSubject']
            this.emailData['body']=findById[0]['templateBody']

         }

      }

    } catch (error) {
      console.log(error)
    }

  },
  data() {
    return {
      emailData: {
        to: "",
        subject: "",
        body: "",
      },
      contacts:[],
      myEmail:"",
      toOptions: [],
      downloadPdf: "",
      openGenerateModal:false,
      pdfBlob:null,
      isDownloading: false,
      ccBccVisible: false,
    };
  },
  methods: {
    handlePdfDownloaded(event){
      this.pdfBlob=event.data
      this.openGenerateModal=false
    },
    handleView(){
            const blob = new Blob([this.pdfBlob], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "google-slides.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    },
    handleGenerateEmail(){
      this.openGenerateModal=true;
    },
    addTag(newTag, index) {
            const parts = newTag.split(', ');

            const tag = newTag;
            this.toOptions.push(tag);
            this.emailData['to']=tag
            // this.selectedBObjecte.push(tag)
        },
    handleChange(){
      const currentContact=this.contacts.filter(d=>d['email']==this.emailData['to'])
      if(this.currentContact.length>0)
      this.emailData['body']=this.replacePlaceholders(this.emailData['body'],currentContact[0])
    },
    replacePlaceholders(template, data) {
            const pattern = /\{\{([^}]+)\}\}/g;
            const matches = template.match(pattern);

            if (matches) {
                matches.forEach(match => {
                    const key = match.slice(2, -2).trim();
                    const placeholder = `{{${key}}}`;
                    
                    if (data.hasOwnProperty(key)) {
                        template = template.replace(new RegExp(placeholder, 'g'), data[key]);
                    } else {
                        template = template.replace(new RegExp(placeholder, 'g'), '');
                    }
                });
            }
            console.log(template)

            return template;
        },

    async handleDownloadAttachment() {

      try {
        if (this.emailparams && this.emailparams['dashboardId']) {
          this.isDownloading = true;
          console.log(this.emailparams['params'])
          // Fetch PDF data from the server with the correct response type
          const response = await Server.downloadPdf(this.emailparams['dashboardId'],this.emailparams['params']);

          // Check if the response contains valid Blob data
          // Create a URL for the Blob object
          const url = window.URL.createObjectURL(response.data);
          window.open(url,"_blank")
          // Create a temporary anchor element for downloading the PDF
          // const a = document.createElement('a');
          // a.href = url;
          // a.download = 'export.pdf';  // Specify the name for download

          // // Append the anchor to the body and trigger the download
          // document.body.appendChild(a);
          // a.click();

          // // Cleanup the DOM and Blob URL
          // document.body.removeChild(a);
          // window.URL.revokeObjectURL(url);
        }

      } catch (error) {
        console.error('Error downloading PDF:', error.message || error);
      } finally {
        this.isDownloading = false;
      }
    }



    ,
    handleSendEmailTest(toEmail) {
      const client = window.google.accounts.oauth2.initTokenClient({
        scope: 'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
        client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
        access_type: 'offline', // Request a refresh token
        prompt: 'consent',
        callback: async (tokenResponse) => {
          if (!tokenResponse.access_token) {
            console.error('Error obtaining access token');
            toast.error("Error while sending email");
            return;
          }

          try {
            // Store the Gmail token for the user
            await Server.updateUserInfo({
              email_id: localStorage.getItem('__email__id'),
              gmailToken: tokenResponse.access_token
            });

            // Send the email
            let resp = (await Server.sendEmail(this.emailData['subject'],toEmail?toEmail: localStorage.getItem('__email__id'), this.emailData['body'], localStorage.getItem('__email__id'),this.pdfBlob)).data;
            console.log(resp);

            // Check if email was successfully sent and show a success message
            if (resp && resp.data.id) {
              this.$toasted.success(
                "Email sent Successfully"
              );
            }
          } catch (error) {
            console.error('Error while sending email:', error);
            toast.error("Error while sending email");
          }
        }
      });

      // Initiate the token request process
      client.requestAccessToken();

    },
    sendEmail(toEmail) {
      console.log("Email Sent", this.emailData);
      this.handleSendEmailTest(toEmail)
      // Clear form after sending
      // this.emailData = { to: "", subject: "", body: "" };
    },
    closeCompose() {
      this.$emit("close")
      // Logic to close compose window
      console.log("Compose closed");
    },
    toggleCcBcc() {
      this.ccBccVisible = !this.ccBccVisible;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Google+Sans&display=swap");

.compose-email {
  font-family: 'Google Sans', sans-serif;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
  /* padding: 16px; */
  /* max-width: 600px; */
  /* margin: 0 auto; Center the component */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compose-header {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  background-color: #f2f2f2;
}

.compose-header h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.form-group {
  margin-bottom: 12px;
  /* padding: 12px; */
}

.email-input,
.email-body {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  outline: none;
  transition: border-bottom-color 0.3s;
}

.email-input:focus,
.email-body:focus {
  border-bottom-color: #1a73e8;
  /* Focus color */
}

.email-body {
  height: 150px;
  resize: vertical;
}

.cc-bcc-toggle {
  background: none;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 8px;
  padding: 0;
}

.compose-footer {
  display: flex;
  justify-content: flex-start;
  padding-top: 12px;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 12px;
}

.send-btn {
  background-color: #4E5FFF;
  color: white;
  border: none;
  border-radius: 12px;
  width: 80px;
  height: 35px;
  cursor: pointer;
  font-size: 14px;
}

.send-btn:hover {
  background-color: #155ab2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .compose-email {
    max-width: 100%;
    padding: 12px;
  }

  .email-body {
    height: 100px;
  }

  .send-btn {
    padding: 8px 12px;
  }
}

.form-body {
  padding: 10px 20px;
}
</style>
<style lang="scss">

.generate-model{
  position: fixed;
  left: 0px !important;
  left: 0px !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 9999999;
  background: white;
  top: -14px;
}
</style>
<template>
    <div class="modalSummary">
      <SummaryNew  :domain="domain" :id="id"/>
    </div>

</template>
<script>
import PulseModal from '../pages/PulseModal.vue';
import Server from './API/Server';
import Modal from './Modal.vue';
import { JWT } from "node-jsonwebtoken";

import PulseView from './PulseView.vue';
import SummaryNew from '../pages/SummaryNew.vue';
export default {
    props:{
        domain:'',
        id:''
    },
    data(){
        return{
            iframeUrl:"",
            domain:"",
            id:"",
            openPulse:false
        }
    },
    components:{
        PulseView,
        Modal,
        SummaryNew,
        PulseModal
    },
    mounted(){
        this.getSummaryInfo();
    },
    methods:{
        getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 1) {
                        companyName = companyName.toString().split(".")[0];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://logo.clearbit.com/' + 'zendesk.com';
                else if (context_link.toString().split("@").length > 1)
                    return 'https://logo.clearbit.com/' + context_link.toString().split("@")[1];

                else if (context_link.split(".").length > 1 ? "" : ".com")
                    return 'https://logo.clearbit.com/' + context_link.toString().split(" ").join("") + ".com";
                else
                    return 'https://logo.clearbit.com/' + context_link;

            } catch (e) {
                console.log(e);
            }
        },
        async openDash(dash) {
            console.log(dash)
            const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data
            let email = localStorage.getItem('__email__id')

            let metabaseNumber = '';

            let customCode = '';
            if (dash && dash.metabaseNumber) {
                metabaseNumber = Number(dash.metabaseNumber);
                this.selectedDashboard = metabaseNumber
                customCode = dash.customCode;
            }
            let params = {};
            if (customCode && JSON.parse(customCode)) {
                let resp = JSON.parse(customCode);
                params = resp
                console.log(resp);
                Object.keys(resp).map((d) => {
                    if (resp[d].includes("{{")) {
                        resp[d] = resp[d].replace("{{", "");
                    }
                    if (resp[d].includes("}}")) {
                        resp[d] = resp[d].replace("}}", "");
                    }
                    if (resp[d].includes("email_id")) {
                        params[d] = localStorage.getItem("__email__id");

                    }
                    if (resp[d].includes("TODAY()")) {
                        const currDate = new Date()
                        if (resp[d].includes("-")) {
                            const val = parseInt(resp[d].split("-")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        if (resp[d].includes("+")) {
                            const val = parseInt(resp[d].split("+")[1])
                            currDate.setDate(currDate.getDate() - val)
                        }
                        params[d] = currDate.toISOString()

                    }

                })
                console.log(resp);
            }
            if (!metabaseNumber) {
                metabaseNumber = 67;
            }
            this.dashNum = metabaseNumber;
            if (userInfo && userInfo.data && userInfo.data.org_id)
                this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const currentDate = new Date();
            // Add two days to the current date
            currentDate.setDate(currentDate.getDate() + 2);
            console.log(params)
            if (metabaseNumber !== 67) {
                this.setParams = params;
            } else {
                this.setParams = {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                };
            }
            const token = await jwt.sign({
                resource: { dashboard: metabaseNumber },
                params: (metabaseNumber !== 67) ? params : {
                    appeq_org_id: this.org_id,
                    current_date: currentDate.toISOString()
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";

            // this.toShowDashboard = iframeUrl
            console.log(iframeUrl)
            this.afterLoadShowAi = true;
            return iframeUrl


        },
        async getSummaryInfo(){
            const data=await Server.getSummaryInfo()
            console.log(data)
            let summaryName='';
            let appeqOrgId=''
            if(data && data.summaries && Array.isArray(data.summaries)){
                summaryName=data.summaries[0]['summary_name']
                appeqOrgId=data.summaries[0]['appeq_org_id']
            }
            console.log(  {summaryname:summaryName,
                orgid:appeqOrgId,
                accountid:this.id,
                accountdomain:this.domain})
            this.iframeUrl=await this.openDash({metabaseNumber:526,customCode:JSON.stringify({
                summaryname:summaryName,
                orgid:appeqOrgId,
                accountid:this.id,
                accountdomain:this.domain
            })})
            // await Server.
        },
        handleOpenPulse(){
            this.openPulse=true
        }
    }
}
</script>
<style lang="scss" scoped>
.modalSummary{
    display: flex;
    height: 100%;
    // align-items: center;
    flex-direction: row;
    padding: 8px;
}
.iframeUrl{
    border: none !important;
    height: 100%;
}
.mainDiv{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
}

</style>
<template>
    <div class="object_configuration">

        <div class="header">
            <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="margin-bottom: 0px;text-transform: capitalize;">{{bObject}}</h2>
        </div>
        <div class="tabs">
            <div @click="changeTab(idx)" :class="{'selectedTab':selectedTab==idx}" class="tab" v-for="(tab,idx) of tabs">
                {{ tab }}
            </div>
        </div>
        <div class="body">
            <Defaultview :bObject="bObject" v-if="selectedTab==0" />
            <MapAccountFields :source="sourceType" :bObject="bObject" v-else-if="selectedTab==1"/>
            <CustomFields :bObject="bObject" v-else-if="selectedTab==2 && (bObject=='accounts' || bObject=='Contacts')" />
            <DataObjectLogs :bObject="bObject" v-else-if="selectedTab==3 || (selectedTab==2 && (bObject!='accounts' && bObject!='Contacts'))" />
            <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;" class="" v-else>
                <img style="height: 300px;" src="../assets/wip.svg" />
                Feature is currently under work
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Defaultview from "../components/Defaultview.vue"
import MapAccountFields from "../components/MapAccountFields.vue";
import CustomFields from "../components/CustomFields.vue";
import DataObjectLogs from "../components/DataObjectLogs.vue";
export default {
    components: { Multiselect, Defaultview, MapAccountFields, CustomFields, DataObjectLogs },
    props:{
        bObject:{},
        source: {type:String},
    },
    mounted(){
        this.sourceType = this.source;
        setTimeout(()=>{
            document.querySelector(".object_configuration").style.right="0px"

        },200)
        if(this.bObject=="accounts" || this.bObject=='Contacts'){
            this.tabs.push("Custom Fields")
        }
        this.tabs.push("Logs")
    },
    data(){
        return{
        tabs:[
            "Default View",
            "Fields Mapping",
        //     "Settings",
        // ],
        ],
        sourceType: null,
        selectedTab:0,
        }
    },
    methods:{
        changeTab(tabIdx){
            this.selectedTab=tabIdx
        },
        handleClose(){
            this.$emit("close")
        }
    }
}
</script>
<style lang="scss" scoped>
    .object_configuration{
        position: fixed;
    right: -60vw;
    transition: right 1s ease-in-out;
    width: 60vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 99;
    border-radius: 20px 0px 0px 20px;
    padding: 13px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);

    }
    .header{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .tab{
        color: black;
        margin-left: 40px;
        font-size: 16px;
        cursor: pointer;
        word-spacing: 2px;
       
    }
    .tabs{
        display: flex;
        align-items: center;
        border-bottom: 1px solid black;
        margin-top: 5px;
        padding: 0px 10px;
        padding-right: 10px;
        :first-child{
            margin-left: 0px !important;
        }
    }
    .selectedTab{
        border-bottom: 2px solid blue;
        font-weight: 600;
    }
    .body{
        margin-top: 20px;
    }
</style>

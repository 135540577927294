<!-- PulseView.vue -->
<template>
    <div @click="handleShowPulse()" style="  
    border-radius: 8px;
    padding: 4px !important;
    justify-content: center;
    display: flex;
    align-items: center;" class="pulseBox">
        <div  v-tooltip="data.description" style=" width: 6px;margin-right: 5px;
    height: 20px;border-radius: 4px;" class="pulseSingleBox" :style="{background:data.health,color:data.health}" v-for="data of pulseData">
            {{ 's' }}
        </div>
        <div 
        v-if="pulseData.length==0"
        >
         ⬜️ Set Pulse

      </div>
        <div style="font-size: 10px;">
          <span>
            {{ lastPulseData.reason }}
          </span>
          <br/>
          <span style="font-size: 8px;display: flex;align-items: center;color: grey;" v-if="lastPulseData.followUpDate">
            <img  src="../assets/img/images.png" style="height: 9px;margin-right: 5px;"/>
            {{ formatDate(lastPulseData.followUpDate) }}
          </span>
        </div>
      <!-- Component content -->
    </div>
  </template>
  
  <script>
  import Server from './API/Server';
  import PulseModal from '../pages/PulseModal.vue';
import Modal from './Modal.vue';
import { formatDate } from 'tough-cookie';
  export default {
    name: 'PulseView',
    components:{
      PulseModal,
      Modal
    },
    props:{
      domain:'',
      id:''
    },
    mounted(){
      this.handleOpenPulse()
      window.parent.addEventListener("message",(ev)=>{
        // console.log(ev)
        ev=ev.data
        if(ev.type=='appeq_pulse_reload' && ev.data.domain==this.domain && ev.data.id==this.id){
            this.handleOpenPulse()

        }
      })
    },
    data(){
        return {
            pulseData:[],
            pulseType:"",
            lastPulseData:{},
            showPulseModal:false
        }
    },
    // Component logic,
    methods:{
       formatDate(dateString) {
    const date = new Date(dateString);

    // Options for formatting
    const options = {
        month: 'long', // 'short' for abbreviated month names
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // year: 'numeric' // omit this if you don't want the year
    };

    return date.toLocaleString('en-US', options);
},
      refreshData(){
        this.handleOpenPulse()
      },
      async handlePulseReload(ev) {
            if (ev && ev.action){
              const data=await Server.readPulseData(this.domain,this.id)
              this.pulseData = data.data.slice(0, 3);
            }
        },
      handleShowPulse(){
        // this.showPulseModal=true;
      },
       async handleOpenPulse(){
            const data=await Server.readPulseData(this.domain,this.id)
            this.pulseData = data.data.slice(-3);
            if(this.pulseData.length>0){
              this.lastPulseData=this.pulseData[this.pulseData.length-1]
            }
          }
    }
  };
  </script>
  
  <style lang="css">
  .pulseBox{
    border: 1px solid #efefef !important;
    padding: 4px !important;
    display: flex;
    align-items: center;
  }
  .pulseSingleBox{
    width: 10px;
    height: 30px;
  }
  /* Component styles */
  </style>
  
<template>
    <div class="taskPopup">
        <h2 style="text-align: center;">Task</h2>
        <img style="position: absolute;right: 0px;top: 0px;cursor: pointer;" src="../assets/img/feed_close.svg"
            @click="close" />
        <div class="row">
            <input :class="{error:saveClicked && tasks['task_name'].length==0}" class="input" v-model="tasks['task_name']" type="text" name="" id="task_name" placeholder="Task title">
            <div style="display: flex; flex-direction: column; align-items: center;" >
                <Multiselect v-model="tasks['type']" class="multiSelect"
                :options="['EMAIL', 'CALL', 'TODO', 'MEETING', 'BUSINESS REVIEW']" />
                <div v-if="tasks['type']=='EMAIL' && !pinClicked" @click="pinClicked=true;" style="cursor: pointer; color: blue;"><img src="../assets/pinIcon.webp" style="height: 20px; cursor: pointer;" alt=""> Email Assist Template</div>
               
            </div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 10px;justify-content: space-between;" v-if="tasks['type']=='EMAIL' && pinClicked" class="">
            <div class="" style="display: flex;align-items: center;">
                Attach Email Assist template related to the task <span style="color: blue;font-size: 10px;cursor: pointer;margin-left: 5px;">(Read more ) </span>
            </div>        
                    <Multiselect  v-model="tasks['email_template']" :options="tableData" class="multiSelect" />

                </div>
        <div class="row">
            <textarea class="input" :class="saveClicked && tasks['task_desc'].length==0?'error':''" v-model="tasks['task_desc']" placeholder="Task Desc" name="" id="task_desc" cols="30"
                rows="8"></textarea>
        </div>
        <div class="row">
            <input class="input" v-tooltip="'Due Date Relative To Nudge Creation'" v-model="tasks['due_date']"
                style="width: 10% !important;flex: 0.5;" type="number" placeholder="Relative Due Date" name="" id="">
            <div style="flex: 1;margin-left: 20px;margin-top: 0px !important;" class="row">
                Assign To : <input type="radio" name="assignee" value="CSM" id="">CSM<input type="radio" name="assignee"
                    value="Unassigned" id="">Unassigned
                    <input v-model="tasks['sendTo']" type="radio" name="assignee"
                    value="user" id="">User
            </div>
        </div>
        <Multiselect v-if="tasks['sendTo']=='user'" class="multiSelect" style="height: 45px;width: 45%;margin-top: 20px !important;margin-left: -15px !important;" :multiple="true" v-model="tasks['selectedUsers']" :options="users" />

        <div style="justify-content: flex-end;" class="row">
            <button @click="close" class="cancelBtn">Cancel</button>

            <button @click="save" class="saveBtn">Save</button>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect"
import Server from "./API/Server"
export default {
    methods: {
        close() {
            this.tasks
            this.$emit("close")
        },
        save() {
            if (!this.tasks.task_name || !this.tasks.task_desc || !this.tasks.due_date) {
                this.$toasted.error("Please fill all the details to save the task")
                if (!this.tasks.task_name) {
                    this.saveClicked=true
                }
                if (!this.tasks.task_desc) {
                    this.saveClicked=true

                }
                if(!this.tasks.due_date){
                    this.saveClicked=true

                }
                    return
            }
            if(this.tasks['email_template'] && this.tasks['email_template'].split("-").length>1){
                this.tasks['email_template']=this.tasks['email_template'].split("-")[1]
            }
            this.$emit("save", { task: this.tasks, isEditing: this.isEditing })
        }
    },
    data() {
        return {
            tasks: {
                task_name: "",
                sendTo:"",
                task_desc: "",
                due_date: '',
                type: "",
                email_template: "",
                saveClicked:false,
                selectedUsers:[],
            },
            users:[],
            tableData: [],
            pinClicked: false,
            isEditing: false,
        }
    },
    async mounted() {
        if(this.editMode==true){
            this.pinClicked=true;
        }
        console.log(this.task)
        if (this.task) {
            this.tasks = { ...this.task.task }
            this.isEditing = true
        }
        if(this.task && this.task.email_template){
            this.pinClicked=true
        }
        let response = await Server.getprompts();
        console.log(response);
        if(response.data){
            console.log('working', response.data)
            for(let i=0;i<response.data.length;i++){
                console.log('rege')
                this.tableData.push(`${response.data[i].promtType}-${response.data[i].name}`);
            }
        }
        console.log(this.tableData)
        const user=await Server.getAllUsers()
        if( user && user.data){
            this.users=user.data.map(d=>d.email_id)
        }
        console.log(user)
    },
    props: {
        task: {},
        editMode: false
    },
    components: {
        Multiselect
    }
}
</script> 
<style scoped lang="scss">
.taskPopup {
    width: 50vw;
    padding: 20px;

}

.row {
    display: flex;
    margin-top: 20px;
    align-items: center;

    input {
        flex: 1;
    }
}

.input {
    border: 1px solid #e8e8e8 !important;
    border-radius: 8px;
    margin-right: 10px;
    padding: 10px;
    outline: none;

}

input[type=radio] {
    flex: 0 !important;
    margin-left: 20px;
    margin-right: 20px !important;
}

textarea {
    width: 100%;
    border-radius: 8px;
    font-size: 12px;
    outline: none;
    border: 1px solid #e8e8e8 !important;
}
</style>
<style>
.multiSelect {
    .multiselect__element {
        font-size: 10px !important;
    }

    .multiselect__option {
        font-size: 10px !important;

        span {
            font-size: 10px !important;

        }
    }

    .multiselect__single {
        font-size: 12px !important;
    }

    flex: 1;
    margin-top: 0px !important;
    width: 200px !important;
    font-size: 10px !important;
    border-radius: 8px !important;

    &::placeholder {
        font-size: 10px !important;
    }

    .multiselect__placeholder {
        font-size: 12px !important;

    }

    .modal-search .modal-dialog span {
        font-size: 10px !important;
    }
}

.cancelBtn {
    width: 20%;
    border-radius: 8px;
    height: 40px;
    border: 1px solid #4e5fff;
    background: white;
    color: #4e5fff;
}
.error{
    border-bottom:  1px solid red !important;
}

.saveBtn {
    width: 20%;
    border-radius: 8px;
    height: 40px;
    margin-left: 20px;
}</style>
<template>
    <div style="" class="">
        <img src="../icons/crossButton.svg" style="height: 14px;cursor: pointer;position: absolute;top: 8px;right: 8px;" @click="handleClose"/>
        <div style="display: flex;align-items: center;padding-left: 20px;border-bottom:  .5px solid hsla(0,0%,76.9%,.74)" class="">
            <img :src="getIcon(domain)" style="height: 25px;"/>
        <span style="margin-bottom: 0px !important;text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #585b5b;
    padding: 10px;">
            {{ getFeedName(domain) }}
        </span>
        </div>
       
  <div class="summaryView">
    <div v-if="!loading"  class="keyData">
        <h3 style="margin-bottom: 8px !important;color: rgb(115, 115, 115) !important;">Key Data</h3 >
    <div v-for="(row, rowIndex) in summaryData" :key="rowIndex" class="summary-row">
      <div v-for="(column, colIndex) in row.appeq_summary_row" :key="colIndex" class="summary-column">
        <!-- <span><strong>Business Object:</strong> {{ column.appeq_summary_column.appeq_summary_bObj }}</span> -->
        <span class="fontLabel"> {{ column.appeq_summary_column.appeq_summary_label }}</span>
        <span v-tooltip="column.appeq_summary_column.appeq_summary_value.data" class="fontValue" v-if="column.appeq_summary_column.appeq_summary_value.data"> {{ column.appeq_summary_column.appeq_summary_value.data.toString().substr(0,17) }}</span>
        <!-- <p v-if="column.appeq_summary_column.appeq_summary_value.error">{{ column.appeq_summary_column.appeq_summary_value.message }}</p> -->
      </div>
    </div>
    
    <h3 style="margin-top: 30px;margin-bottom: 8px !important;color: rgb(115, 115, 115) !important;">Signals</h3 >
        <div @click="handlePulseOpen" style="width: 40%;cursor: pointer;" class="">
        <PulseView  :domain="domain" :id="id"/>
        
    </div>
    
  

</div>
<div v-else>
        <img src="../icons/loadingripple.svg" style="height: 120px;"/>
    </div>
<div class="dashboardView">
        <img v-if="!dashboardIframe" @click="handleredirec" style="opacity: 0.6;" src="../icons/Charts.png" />
        <iframe style="height: 100%;border: none !important;" v-else :src="dashboardIframe"></iframe>
    </div>
  </div>
  <h3 style="margin-top: 10px;margin-bottom: 8px !important;color: rgb(115, 115, 115) !important;margin-left: 30px;">Related</h3 >
 
  <div class="bObjects">

        <div @click="handleSingleClick(bObj.business_object_name)" :class="{'selectedBObj':bObj.business_object_name==selectedBobject}" class="singleObj" v-for="bObj of bObjects">
            <span>{{ bObj.business_object_name }}</span>
            <img src="../icons/downarrow.png"  />
        </div>
    </div>
    <div v-if="selectedBobject">
    <div v-if="!feedLoading" style="height: 100%;"> 
        <iframe style="border: none !important;height: 25vh;" v-if="iframeUrl"  :src="iframeUrl" />
    </div>
    <div style="display: flex;align-items: center;justify-content: center;" v-else>
        <img src="../icons/loadingripple.svg" style="height: 45px;"/>
    </div>
</div>
    <div style="margin-top: 20px;"  v-if="openPulse">
            <Modal style="left: 25% !important;right: 30% !important;background-color: none !important;background: none !important;height: 100%;" class="modal-new-pulse" :show="openPulse" v-if="openPulse">
                <!-- <img src="../icons/crossButton.svg" style="height: 14px;cursor: pointer;" @click="openPulse=false"/> -->
                <PulseModal  @close="openPulse = false" :pulseType="pulseType"
                :account_domain="domain" :account_id="id" :selectedPulseOpen="0"/>
            </Modal>
        </div>
</div>

</template>

<script>
import Server from '../components/API/Server';
import PulseView from '../components/PulseView.vue';
import { JWT } from "node-jsonwebtoken";
import Modal from '../components/Modal.vue';
import PulseModal from './PulseModal.vue';
export default {
  name: "SummaryTable",
  props:{
    id:"",
    domain:""
  },
  components:{
    PulseView,
    PulseModal,
    Modal
  },
  data() {
    return {
      summaryData:[],
      bObjects:[],
      loading:false,
      dashboardIframe:"",
      iframeUrl:"",
      feedLoading:false,
     loaders:[false,false,false],
      openPulse:false,
      selectedBobject:""
    };
  },
  async mounted(){
    this.getSummaryData()
    const data=(await Server.getAllowedApps2(localStorage.getItem("__email__id"))).data
    if(data && data.data){

    console.log("data is ---")
    console.log(data);
    const features=data.data.features
    
    if (features ) {
        // features = JSON.parse(features)
        const dashFeature=features.filter(feature=>{
            if(feature && feature.feature_name == "appeqTrendsAndDashboard" && feature.enabled==true){
                return true
            }
        })
        console.log(dashFeature,"-dashfeature")
        if(dashFeature && dashFeature.length>0){
            this.dashboardIframe=await this.openDash({metabaseNumber:dashFeature[0].dashboardNum.toString(),customCode:JSON.stringify({"domain":this.domain,"id":this.id})})
        }
                           
        }
    }
  },
  methods:{
    handleClose(){
        window.parent.postMessage({action:"__appeq_close_summary", data:""},"*");
        this.$emit("close")
    },
    handlePulseOpen(){
        this.openPulse=true

    },

    handleredirec(){
        window.open("https://docs.google.com/forms/d/e/1FAIpQLScSxRbvpYo5snJzBZTPmF8n6MmIvXrCJyRy4s5uid1jdkKogw/viewform?usp=pp_url&entry.540104862=manoranjan@appeq.ai","_blank")
    },
    getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 2) {
                        companyName = companyName.toString().split(".")[1] + '.' + companyName.toString().split(".")[2];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
    async openDash(dash) {
            console.log(dash)
            // const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data
            let email = localStorage.getItem('__email__id')

            let metabaseNumber = '';

            let customCode = '';
            if (dash && dash.metabaseNumber) {
                metabaseNumber = Number(dash.metabaseNumber);
                this.selectedDashboard = metabaseNumber
                customCode = dash.customCode;
            }
            let params = {};
            if (customCode && JSON.parse(customCode)) {
                let resp = JSON.parse(customCode);
                params = resp
            }
            if (!metabaseNumber) {
                metabaseNumber = 67;
            }
            this.dashNum = metabaseNumber;
           
            var METABASE_SITE_URL = "https://app.appeq.ai/bi";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            const currentDate = new Date();
            // Add two days to the current date
            currentDate.setDate(currentDate.getDate() + 2);
            console.log(params)
            if (metabaseNumber !== 67) {
                this.setParams = params;
            } else {
                this.setParams = {
                    current_date: currentDate.toISOString()
                };
            }
            const token = await jwt.sign({
                resource: { dashboard: metabaseNumber },
                params: (metabaseNumber !== 67) ? params : {
                    current_date: currentDate.toISOString()
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            return iframeUrl

            this.toShowDashboard = iframeUrl
            console.log(iframeUrl)
            this.afterLoadShowAi = true;


        },
    async handleSingleClick(bObject){
        this.feedLoading=true
        if(this.selectedBobject == bObject){
            this.selectedBobject=""
        }else{
            this.selectedBobject=bObject
            const userInfo = (await Server.getUsersData(localStorage.getItem("__email__id"))).data

            if(this.selectedBobject =="tasks"){
                const keys=["account_domain", "task_type", "desc", "status", "duedate","assignee",].join(",");
                this.iframeUrl=await this.openDash({metabaseNumber:"558",customCode:JSON.stringify({"keys":keys,"business_object_name":bObject,id:this.id,domain:this.domain,orgid:userInfo.data.org_id})})
                this.feedLoading=false;
                return
            }
            const keys=await Server.getAdminConfigsForSumm()
            
            console.log(keys,"key")
            if(keys && keys.data && keys.data.length>0 &&keys.data[0] && keys.data[0].feeds_configuration){
                const selectedKeys=keys.data[0].feeds_configuration
                const currentB=selectedKeys.filter(d=>d.business_object_name==bObject)
                if(currentB && currentB.length>0){
                    const toPass=currentB[0].keys.join(",")
                    this.iframeUrl=await this.openDash({metabaseNumber:"558",customCode:JSON.stringify({"keys":toPass,"business_object_name":bObject,id:this.id,domain:this.domain,orgid:userInfo.data.org_id})})

                }else{
                   const data= (await Server.getConfiguratorKeys(bObject)).data
                   if(data && data.keys){
                    const toPass=data.keys.join(",")
                    this.iframeUrl=await this.openDash({metabaseNumber:"558",customCode:JSON.stringify({"keys":toPass,"business_object_name":bObject,id:this.id,domain:this.domain,orgid:userInfo.data.org_id})})

                   }
                   console.log("No keys present")
                   console.log(data)
                }

            }else{
                   const data= (await Server.getConfiguratorKeysNew(bObject)).data
                   if(data && data.keys){
                    const toPass=data.keys.join(",")
                    this.iframeUrl=await this.openDash({metabaseNumber:"558",customCode:JSON.stringify({"keys":toPass,"business_object_name":bObject,id:this.id,domain:this.domain,orgid:userInfo.data.org_id})})

                   }
                   console.log("No keys present")
                   console.log(data)
                }

        }
        this.feedLoading=false

    },
    getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'salesforce.com' + '&sz=128';
                else
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
            } catch (e) {
                console.log(e);
            }
        },
    async getSummaryData(){
        try {
            this.loading=true
        console.log("checking for data")
        const data=await Server.fetchSummaryDataValue(this.domain,this.id)
        console.log("data is :"+JSON.stringify(data))
        if(data && data.data && data.data[0] && data.data[0].data){
            this.summaryData=data.data[0].data
        }
        this.loaders[0]=false;

        const bObj=await Server.googleSheetCredentials(localStorage.getItem("__email__id"))
        console.log(bObj)
        if(bObj && bObj.data){
            this.bObjects=bObj.data
            this.bObjects = this.bObjects.filter(d => 
  !["accounts"].includes(d.business_object_name)
);
        }
this.loading=false

            
    } catch (error) {
            console.log(error)
        }
    }
  }
};
</script>

<style lang="scss" scoped>
.summary-row {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
//   justify-content: space-around;
}

.summary-column {
  margin-right: 20px;
  flex: 0.50;
  display: flex;
  align-items: center;
  text-align: left;
  span{
    flex: 0.4;
  }
}
.fontLabel{
    font-size: 0.82vw;
}
.summaryView{
    display: flex;
    padding-bottom: 0px !important;
    padding: 30px;
}
.keyData{
    flex:0.55;
}
.dashboardView{
    flex: 0.45;
}
.fontValue{
    font-weight: 600;

    font-size: 0.7vw;
}
.bObjects{
    display: flex;
    align-items: center;
    width: 92vw;
    overflow-x: auto;
    padding-left: 14px;
    overflow-y: hidden;
    height: 40px;
    &:first-child{
        margin-left: 0px !important;

    }
    &:nth-child(1n){
        margin-left: 0px !important;
    }
}
.selectedBObj{
    color: blue !important;
    font-weight: 600 !important; 
}
.singleObj{
    font-size: 0.75vw !important;
    margin-left: 30px;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 600;
    align-items: center;
    display: flex;
    width: 100%;
    text-wrap: nowrap;
    flex: 1;
    img{
        margin-left: 6px;
        height: 9px !important;
    }
}
h3{
    font-size: 1.17em !important;
    font-weight: bold !important;
}
</style>
<style>
.modal-new-pulse{
    .modal-content{
        height: 64vh !important;
    }
}
</style>